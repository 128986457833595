import styled from 'styled-components';

export const Container = styled.section`
  color: ${({theme}) => theme.palette.secondary.main};
  margin-bottom: 1rem;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  > div {
    background: ${({theme}) => theme.palette.secondary.light};
    border-radius: 5px;
    padding: 0.5rem;
    height: fit-content;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    > section {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  > div:first-child aside {
    background-color: ${({theme}) => theme.palette.common.white};
    border: 2px solid grey;
    border-radius: 5px;
    max-width: 175px;
    min-width: 175px;

    & .MuiInputBase-root {
      fieldset {
        border: none;
      }
    }
  }

  > div:last-child {
    height: auto;

    > div {
      flex: 1;
      max-width: 450px;
      min-width: 235px;
    }
  }

  > div span {
    font-size: 16px;
  }
`;
