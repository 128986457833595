import { AiOutlineSearch } from 'react-icons/ai'
import { components } from 'react-select'

export const Control = ({ children, ...props }: any): JSX.Element => {
  return (
    <components.Control {...props}>
      <AiOutlineSearch />
      {children}
    </components.Control>
  )
}

export const MultiValueContainer = (props: any): JSX.Element => {
  return <components.MultiValueContainer {...props} children={<div>{`Buscar ${props.selectProps.value.length} Instituições`}</div>} />
}

export const styles = {
  control: (css: any) => ({ ...css, paddingLeft: '1rem', border: '2px solid grey' })
  // multiValue: (base: any, state: any) => ({ ...base, display: state.index === 0 ? 'inherit' : 'none', background: 'none' })
}
