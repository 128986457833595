import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import React, { FC } from 'react';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme/theme';
import { SelectDefaultValue } from 'protocols';
import { parseNumberToInt } from 'utils';
import {
  ChartContainer,
  Container,
  NoData,
  TooltipContainer,
  TotalContainer,
} from './styles';

const CustomTooltip: FC = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        Qtd de instituições: {payload[0].value}
      </TooltipContainer>
    );
  }
  return null;
};
interface AverageResponseTimePageFiveProps {
  data: any;
  averageTotalMediumPriority: number;
  chartOrder: SelectDefaultValue;
  mobile: boolean;
  loading: boolean;
}
const AverageResponseTimePageFive: FC<AverageResponseTimePageFiveProps> = ({
  data,
  chartOrder,
  averageTotalMediumPriority,
  mobile,
  loading,
}) => {
  return (
    <>
      {(!data || loading) && (
        <Skeleton variant='rect' width={'100%'} height={500} />
      )}

      {(parseNumberToInt(averageTotalMediumPriority) === 0 || (data && data.length === 0)) && !loading && <NoData>Nenhum dado encontrado</NoData>}

      {parseNumberToInt(averageTotalMediumPriority) !== 0 && (data && data.length > 0) && !loading && (
        <Container>
          <ChartContainer>
            <ResponsiveContainer minWidth={200} minHeight={300}>
              <ComposedChart
                layout='vertical'
                data={_.orderBy(
                  data,
                  [chartOrder.value.by],
                  [chartOrder.value.type],
                )}
              >
                <XAxis
                  type='number'
                  hide={true}
                  domain={[0, (dataMax: number) => dataMax * 1.2]}
                />
                <YAxis
                  dataKey='range'
                  type='category'
                  width={100}
                  tickLine={false}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey='value'
                  barSize={40}
                  fill={theme.palette.warning.main}
                >
                  <LabelList dataKey='value' position='right' />
                </Bar>
                {!mobile && <Tooltip content={<CustomTooltip />} />}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartContainer>

          <TotalContainer textColor='#000' bgColor={theme.palette.warning.main}>
            <h3>{`${parseNumberToInt(
              averageTotalMediumPriority,
            )} milissegundos`}</h3>
            <p>TEMPO DE RESPOSTA MÉDIO DA API</p>
          </TotalContainer>
        </Container>
      )}
    </>
  );
};

export default AverageResponseTimePageFive;
