import _ from 'lodash'

export const makeChartData = (data: any): any => {
  const idList: { [index: string]: number } = {
    'Solicitação de acesso': 0,
    Incidentes: 1,
    'Solicitação de Informações': 2,
    'Solicitação de Melhorias': 3,
    Cancelado: 4
  }
  const makedData = data.map((item: any) => {
    const word = item.problem_type.replace(/\D+\s(\D+)/, '$1')
    const firstLetter: string = word.charAt(0).toUpperCase()
    const restWord: string = word.slice(1).toLowerCase()

    return { total: Number(item.total), problem_type: firstLetter + restWord, id: idList[item.problem_type] }
  })

  return _.orderBy(makedData, ['id'])
}
