import { transformToMonthName } from '../../../../../utils'
interface DataProps {
  total: string
  problem_type: string
}

interface DataOriginProps {
  [index: string]: DataProps[]
}
export const makeChartData = (data: DataOriginProps): any => {
  const graphData = Object.keys(data).map((key: string) => {
    const newFormatData: { [index: string]: any } = {}
    data[key].forEach((item: DataProps) => {
      newFormatData[item.problem_type] = item.total
      newFormatData.Total = data[key].reduce((ac: number, act: any) => ac + Number(act.total), 0)
    })

    return { ...newFormatData, mes: transformToMonthName(key) }
  })

  return graphData
}
