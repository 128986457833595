/* eslint-disable no-multiple-empty-lines */
export const checkInstitutionsListGraphsStatus = (data: any, status: boolean, filteredData = []): 'LOADING' | 'NODATA' | 'HASDATA' => {
  if (!data || status) return 'LOADING'

  if (data && data.length === 0 && !status) return 'NODATA'

  if (data && data.length > 0 && !status && filteredData.length === 0) return 'NODATA'

  if (data && data.length > 0 && !status && filteredData.length > 0) return 'HASDATA'

  return 'LOADING'
}
