import { api, pcm } from './api'

export interface SubmitParams {
  firstDate?: string
  lastDate?: string
  servers?: string[]
  type?: 'performanceMetrics' |
  'averageAvailability' |
  'averageAvailabilityByServer' |
  'successfulCalls' |
  'successfulCallsByServer' |
  'errorsCalls' |
  'errorsCallsByServer' |
  'totalRejected' |
  'rejectedByServer' |
  'averageResponse' |
  'averageResponseByServer' |
  'highPriority' |
  'highPriorityByServer' |
  'mediumPriority' |
  'mediumPriorityByServer'
}
export const getMirrorReport = async ({ firstDate, lastDate, servers, type }: SubmitParams): Promise<any> => {
  try {
    if (firstDate || lastDate || servers || type) {
      const { data } = await api.get(`/mirrorReport?firstDate=${firstDate}T00:00:00Z&lastDate=${lastDate}T23:59:59Z&servers=${servers}&type=${type}`)
      return data
    }
    
    var { data } = await pcm.get('/dashboard-metrics/servers')
    
    const filteredData = data.map((org: any) => {
      return {
        orgId: org.id,
        orgName: org.value
      }
    }).sort((a: any, b: any) => {
      return (a.orgName > b.orgName) ? 1 : ((b.orgName > a.orgName) ? -1 : 0);
    })

    return filteredData
  } catch (error) {
    return undefined
  }
}
