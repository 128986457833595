import React, {FC, useEffect, useState} from 'react';
import logo from 'assets/logo/logo.png';
import {Container, Content, LogoContainer} from './styles';
import banner from 'assets/banners/banner-big.png';

const Header: FC = () => {
  const [title, setTitle] = useState<string>('');
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const makeTitle = (top: number): string => {
    const titleVisaoGeral =
      document.getElementById('title-visao-geral')?.offsetTop;
    const titleIndicadoresConsolidados = document.getElementById(
      'title-indicadores-consolidados',
    )?.offsetTop;
    const titleIndicadoresInstituicoesParticipantes = document.getElementById(
      'title-indicadores-instituicoes-participantes',
    )?.offsetTop;
    const titleDiretorio =
      document.getElementById('title-diretorio')?.offsetTop;

    if (titleDiretorio && top + 90 >= titleDiretorio)
      return 'Elementos de Infraestrutura Compartilhada: Service Desk, Portal e Diretório';
    if (
      titleIndicadoresInstituicoesParticipantes &&
      top + 90 >= titleIndicadoresInstituicoesParticipantes
    )
      return 'APIs dos Participantes: Indicadores das Instituições Participantes';
    if (
      titleIndicadoresConsolidados &&
      top + 90 >= titleIndicadoresConsolidados
    )
      return 'APIs dos Participantes: Indicadores Consolidados';
    if (titleVisaoGeral && top + 90 >= titleVisaoGeral)
      return 'Visão Geral do Open Insurance';

    return '';
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setTitle(makeTitle(window.scrollY));
    });

    return () => window.removeEventListener('scroll', () => '');
  }, []);

  return (
    <Container>
      <LogoContainer hasTitle={!!title} mobile={isMobile}>
        <img src={logo} alt='Open Insurance' height='60' width='100%' />
        {title && <h2>{title}</h2>}
      </LogoContainer>
      <Content>
        <div className='info'>
          <h1>
            Dados estatísticos de desempenho e disponibilidade do Open Insurance
          </h1>
          <p>
            Veja como as <b>APIs dos Participantes</b> e os{' '}
            <b>Elementos da Infraestrutura Compartilhada</b> do Open Insurance
            estão funcionando com as principais métricas de desempenho.
          </p>
          <p>
            As informações disponibilizadas são de responsabilidade das
            instituições participantes, cabendo ao Open Insurance Brasil apenas
            reportá-las. Eventuais esclarecimentos deverão ser prestados
            diretamente pelas instituições de referência.
          </p>
          <p>
            Caso os dados da sua instituição não estejam sendo reportados, entre
            em contato com a sua associação.
          </p>
        </div>

        <div>
          <img src={banner} alt='' />
        </div>
      </Content>
    </Container>
  );
};

export default Header;
