import Skeleton from '@material-ui/lab/Skeleton';
import {endOfMonth, getTime, startOfMonth, subMonths} from 'date-fns';
import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {DateValuesOnlyWithDatePickers} from 'protocols';
import {sysAidGetAtendimentosTempoMedio} from 'services/sysAidRequests';
import {
  parseNumberToFixedTwo,
  parseNumberToLocale,
  transformToMonthName,
} from 'utils';
import FilterBar from 'components/FilterBarOnlyWithDatePicker';
import {
  ChartContainer,
  Container,
  ErrorContainer,
  InfoFooterContainer,
  LegendContainer,
  LegendItem,
  ScrollInfo,
  TooltipContainer,
} from './styles';

interface CustomLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}
const CustomLabel: any = ({x, y, width, height, value}: CustomLabelProps) => (
  <g>
    <text
      x={x + width / 2}
      y={y - 10}
      fill='#000'
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {parseNumberToLocale(parseNumberToFixedTwo(value), 2)}
    </text>
  </g>
);

const renderLegend = (props: any) => {
  const {payload} = props;

  return (
    <LegendContainer>
      {payload.map((entry: any, index: any) => (
        <LegendItem key={`item-${index}`} iconColor={entry.payload.fill}>
          <div></div>
          <p>Média de atendimentos por dia</p>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>
          Período: {`${transformToMonthName(payload[0].payload.mes)}`}
        </span>
        <span>
          Atendimentos:{' '}
          {parseNumberToLocale(parseNumberToFixedTwo(payload[0].value), 2)}
        </span>
      </TooltipContainer>
    );
  }
  return null;
};

const CustomXAxisTick = (props: any): any => {
  const {x, y, payload, fill} = props;

  return (
    <g>
      <text x={x} y={y} dy={16} textAnchor='middle' fill={fill}>
        {payload.value ? transformToMonthName(payload.value) : ''}
      </text>
    </g>
  );
};

const CustomYAxisTick = (props: any): any => {
  const {x, y, payload, fill} = props;

  return (
    <g>
      <text x={x} y={y} textAnchor='end' fill={fill}>
        {parseNumberToLocale(parseNumberToFixedTwo(payload.value), 2)}
      </text>
    </g>
  );
};

interface AtendimentosTempoMedioProps {
  mobile: boolean;
}
const AtendimentosTempoMedio: FC<AtendimentosTempoMedioProps> = ({mobile}) => {
  const [data, setData] = useState<any[] | undefined>();
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const [dateToFilter, setDateToFilter] =
    useState<DateValuesOnlyWithDatePickers>({
      firstDate: startOfMonth(subMonths(new Date(), 1)),
      lastDate: endOfMonth(subMonths(new Date(), 1)),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const getData = (): void => {
    if (dateToFilter) {
      setLoading(true);
      sysAidGetAtendimentosTempoMedio([
        `${getTime(dateToFilter.firstDate)}`,
        `${getTime(dateToFilter.lastDate)}`,
      ])
        .then(response => {
          if (!response.data) throw Error('Erro na obtenção dos dados');
          if (Object.keys(response.data).length === 0)
            throw Error('Nenhum dado encontrado');

          setData(_.sortBy(response.data, ['mes']));
          setLoading(false);
          setError({status: false, message: ''});
        })
        .catch(err => {
          setData(undefined);
          setLoading(false);
          setError({status: true, message: err.message});
        });
    }
  };

  useEffect(() => {
    getData();
  }, [dateToFilter]);

  const getDataToExportCSV = (): any => {
    if (data) {
      return data.map(({atendimentos_dia: atendimentosDia, mes}: any): any => ({
        mes: transformToMonthName(mes),
        atendimentos_dia: atendimentosDia,
      }));
    }
    return '';
  };

  return (
    <>
      {!data && loading ? (
        <Container>
          <header>
            <div>
              <h1>Média de Atendimentos por Dia no Service Desk</h1>
              <p>Média diária registrada no período selecionado</p>
            </div>

            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />
          </header>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </Container>
      ) : (
        <Container>
          <CSVLink
            data={getDataToExportCSV()}
            filename={'atendimentos-dia.csv'}
            style={{
              display: !mobile ? 'none' : 'block',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <h3>
              <FaSave color={theme.palette.secondary.main} />
            </h3>
          </CSVLink>

          <header>
            <div>
              <h1>Média de Atendimentos por Dia no Service Desk</h1>
              <p>Média diária registrada no período selecionado</p>
            </div>

            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />

            <CSVLink
              data={getDataToExportCSV()}
              filename={'atendimentos-dia.csv'}
              style={{display: mobile ? 'none' : 'block'}}
            >
              <h3>
                <FaSave color={theme.palette.secondary.main} />
              </h3>
            </CSVLink>
          </header>

          {error.status && (
            <ErrorContainer>
              {/* <h3>Ops! Algo deu errado.</h3>
              <p>Tente novamento mais tarde.</p> */}
              <p>{error.message}</p>
            </ErrorContainer>
          )}

          {loading && !error.status ? (
            <div style={{width: '100%', margin: 'auto'}}>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </div>
          ) : (
            !error.status && (
              <>
                <ChartContainer onScroll={() => setShowScrollInfo(false)}>
                  <ResponsiveContainer minHeight={300} minWidth={500}>
                    <ComposedChart data={data}>
                      <XAxis
                        dataKey='mes'
                        type='category'
                        tick={<CustomXAxisTick />}
                      />
                      <YAxis
                        type='number'
                        padding={{top: 20}}
                        tickLine={false}
                        tick={<CustomYAxisTick />}
                      />
                      <Bar
                        isAnimationActive={false}
                        dataKey='atendimentos_dia'
                        fill={theme.palette.primary.main}
                      >
                        <LabelList
                          dataKey='atendimentos_dia'
                          position='top'
                          content={CustomLabel}
                        />
                      </Bar>
                      <Legend content={renderLegend} />
                      {!mobile && <Tooltip content={<CustomTooltip />} />}
                    </ComposedChart>
                  </ResponsiveContainer>

                  <ScrollInfo showScrollInfo={showScrollInfo}>
                    <p>
                      Pressione e arraste para a esquerda para visualizar o
                      restante do gráfico
                    </p>
                  </ScrollInfo>
                </ChartContainer>
                <InfoFooterContainer>
                  <p>
                    Para o cálculo da média diária, são desconsiderados dias em
                    que não há abertura de chamados.
                  </p>
                </InfoFooterContainer>
              </>
            )
          )}
        </Container>
      )}
    </>
  );
};

export default AtendimentosTempoMedio;
