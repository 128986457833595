import Skeleton from '@material-ui/lab/Skeleton';
import {endOfMonth, getTime, startOfMonth, subMonths} from 'date-fns';
import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {DateValuesOnlyWithDatePickers} from 'protocols';
import {sysAidGetMotivosChamados} from 'services/sysAidRequests';
import FilterBar from '../../../FilterBarOnlyWithDatePicker';
import {makeChartData} from './helpers/makeChartData';
import {
  ChartContainer,
  Container,
  ErrorContainer,
  GraphSubtitle,
  GraphTitle,
  InfoFooterContainer,
  TooltipContainer,
} from './styles';

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Tipo: {payload[0].payload.problem_type}</span>
        <span>Quantidade: {payload[0].value}</span>
      </TooltipContainer>
    );
  }
  return null;
};

interface MotivosChamadosProps {
  mobile: boolean;
}
const MotivosChamados: FC<MotivosChamadosProps> = ({mobile}) => {
  const [data, setData] = useState<any[] | undefined>();
  const [dateToFilter, setDateToFilter] =
    useState<DateValuesOnlyWithDatePickers>({
      firstDate: startOfMonth(subMonths(new Date(), 1)),
      lastDate: endOfMonth(subMonths(new Date(), 1)),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const getData = (): void => {
    if (dateToFilter) {
      setLoading(true);
      sysAidGetMotivosChamados([
        `${getTime(dateToFilter.firstDate)}`,
        `${getTime(dateToFilter.lastDate)}`,
      ])
        .then(response => {
          if (!response.data) throw Error('Erro na obtenção dos dados');
          if (Object.keys(response.data).length === 0)
            throw Error('Nenhum dado encontrado');

          setData(makeChartData(response.data.undefined));
          setLoading(false);
          setError({status: false, message: ''});
        })
        .catch(err => {
          setData(undefined);
          setLoading(false);
          setError({status: true, message: err.message});
        });
    }
  };
  useEffect(() => {
    getData();
  }, [dateToFilter]);

  const getDataToExportCSV = (): any => {
    if (data) {
      return data.map(({problem_type: problemType, total}: any): any => ({
        problem_type: problemType,
        total,
      }));
    }
    return '';
  };

  return (
    <>
      {!data && loading ? (
        <Container>
          <header>
            <div>
              <GraphTitle>Motivos de Chamados no Service Desk</GraphTitle>
              <GraphSubtitle>
                Quantidade de chamados por tipo no período selecionado
              </GraphSubtitle>
            </div>

            <FilterBar
              blockInitialDate={new Date(2021, 4, 1)}
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />
          </header>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </Container>
      ) : (
        <Container>
          <CSVLink
            data={getDataToExportCSV()}
            filename={'motivos-chamados.csv'}
            style={{
              display: !mobile ? 'none' : 'block',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <h3>
              <FaSave color={theme.palette.secondary.main} />
            </h3>
          </CSVLink>

          <header>
            <div>
              <GraphTitle>Motivos de Chamados no Service Desk</GraphTitle>
              <GraphSubtitle>
                Quantidade de chamados por tipo no período selecionado
              </GraphSubtitle>
            </div>

            <FilterBar
              blockInitialDate={new Date(2021, 4, 1)}
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />

            <CSVLink
              data={getDataToExportCSV()}
              filename={'motivos-chamados.csv'}
              style={{display: mobile ? 'none' : 'block'}}
            >
              <h3>
                <FaSave color={theme.palette.secondary.main} />
              </h3>
            </CSVLink>
          </header>

          {error.status && (
            <ErrorContainer>
              {/* <h3>Ops! Algo deu errado.</h3>
              <p>Tente novamento mais tarde.</p> */}
              <p>{error.message}</p>
            </ErrorContainer>
          )}

          {loading && !error.status ? (
            <div style={{width: '100%', margin: 'auto'}}>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </div>
          ) : (
            !error.status && (
              <>
                <ChartContainer>
                  <ResponsiveContainer minHeight={300}>
                    <ComposedChart layout='vertical' data={data}>
                      <XAxis
                        type='number'
                        hide
                        domain={[
                          0,
                          (dataMax: number) =>
                            mobile ? dataMax * 1.4 : dataMax * 1.2,
                        ]}
                      />
                      <YAxis
                        dataKey='problem_type'
                        type='category'
                        width={180}
                        padding={{top: 20}}
                        tickLine={false}
                      />
                      <Bar
                        isAnimationActive={false}
                        dataKey='total'
                        fill={theme.palette.primary.light}
                      >
                        <LabelList dataKey='total' position='right' />
                      </Bar>
                      {!mobile && <Tooltip content={<CustomTooltip />} />}
                    </ComposedChart>
                  </ResponsiveContainer>
                </ChartContainer>
                <InfoFooterContainer>
                  <ul>
                    <li>
                      <b>Acesso:</b> chamados aberto para solicitação de
                      acessos.
                    </li>
                    <li>
                      <b>Incidentes:</b> chamados abertos para comunicação de
                      falhas ou degradação de qualidade em algum serviço.
                    </li>
                    <li>
                      <b>Informações:</b> chamados abertos para solicitação de
                      informações.
                    </li>
                    <li>
                      <b>Melhorias:</b> chamados aberto para indicação de
                      melhorias.
                    </li>
                    <li>
                      <b>Cancelado:</b> chamados cancelados.
                    </li>
                  </ul>
                </InfoFooterContainer>
              </>
            )
          )}
        </Container>
      )}
    </>
  );
};

export default MotivosChamados;
