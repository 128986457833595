import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  & > header > a {
    @media (max-width: 425px) {
      margin-left: auto;
      margin-top: 1rem;
    }
  }

  & > footer {
    width: 100%;
    text-align: center;
  }
`;