import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;
const basePcmURL = process.env.REACT_APP_PCM_PRD_API;

export const api = axios.create({baseURL});

export const pcm = axios.create({baseURL: basePcmURL});

export default api


