import React, {FC} from 'react';
import logo from '../../assets/logo/logo-white.svg';
import {Container, Content} from './styles';

const {REACT_APP_PORTAL_URL, REACT_APP_CONFLUENCE_URL} = process.env;

const Footer: FC = () => {
  return (
    <Container>
      <Content>
        <div className='wrapper-img'>
          <img src={logo} alt='Logo Open Baking' />
          <div className='footer-menu-links'>
            <a
              href={`${REACT_APP_PORTAL_URL}`}
              target='_blank'
              rel='noreferrer'
            >
              cidadão
            </a>
            <a
              href={`${REACT_APP_CONFLUENCE_URL}`}
              target='_blank'
              rel='noreferrer'
            >
              desenvolvedores
            </a>
            <a
              href={`${REACT_APP_PORTAL_URL}/cidadao/quem-participa/`}
              target='_blank'
              rel='noreferrer'
            >
              instituições participantes
            </a>
          </div>
        </div>
      </Content>
      <div className='info-footer'>
        <p>
          © 2021 - Todos os Direitos Reservados - Estrutura Inicial do Open
          Insurance
        </p>
      </div>
    </Container>
  );
};

export default Footer;
