import styled from 'styled-components';

export const Container = styled.div`
  padding: 4rem 0;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  & h1 {
    font-size: 1.5rem;
  }
`;

export const SkeletonsContainer = styled.div`
  margin: auto;
  width: 90%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.h1`
  background-color: ${({theme}) => theme.palette.primary.main};
  color: ${({theme}) => theme.palette.common.white};
  margin: 2rem 0 0 0;
  padding: 0.5rem;
  text-align: center;
`;
