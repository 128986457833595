import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'theme/theme';
import { SelectDefaultValue } from 'protocols';
import { Container, NoData, TooltipContainer } from './styles';

const CustomTooltip: FC = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        Qtd de instituições: {payload[0].value}
      </TooltipContainer>
    );
  }
  return null;
};

interface RejectedCallsPageOneProps {
  data: any;
  chartOrder: SelectDefaultValue;
  mobile: boolean;
  loading: boolean;
}
const RejectedCallsPageOne: FC<RejectedCallsPageOneProps> = ({
  data,
  chartOrder,
  mobile,
  loading,
}) => {
  var noData: boolean = false
  if (data) {
    noData = data.every((element: any, index: any) => {
      if (element.value !== 0) return false
      else return true
    })
  }

  return (
    <>
      {(!data || loading) && (
        <Skeleton variant='rect' width={'100%'} height={500} />
      )}

      {(noData || (data && data.length === 0)) && !loading && <NoData>Nenhum dado encontrado</NoData>}

      {!noData && (data && data.length > 0) && !loading && (
        <Container>
          <ResponsiveContainer minHeight={300}>
            <ComposedChart
              layout='vertical'
              width={500}
              data={_.orderBy(
                data,
                [chartOrder.value.by],
                [chartOrder.value.type],
              )}
            >
              <XAxis
                type='number'
                hide={true}
                domain={[0, (dataMax: number) => dataMax * 1.2]}
              />
              <YAxis
                dataKey='range'
                type='category'
                width={100}
                tickLine={false}
              />
              <Bar
                isAnimationActive={false}
                dataKey='value'
                barSize={40}
                fill={theme.palette.primary.main}
              >
                <LabelList dataKey='value' position='right' />
              </Bar>
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>
        </Container>
      )}
    </>
  );
};

export default RejectedCallsPageOne;
