import ptBR from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Dashboard from 'pages/Dashboard';
import GlobalStyle from 'styles/global';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/theme';
import { AdditionalInfo } from 'components/AdditionalInfo';

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <GlobalStyle />
      <Header />
      <Dashboard />
      <AdditionalInfo />
      <Footer />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export { App };
