const formatterWithOnlyMonth: { [index: string]: string } = {
  '01': 'Jan',
  '02': 'Fev',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'Mai',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez'
}

export const transformToMonthName = (key: string): string => `${formatterWithOnlyMonth[key.replace(/(\d+)\/(\d+)/, '$1')]}-${key.replace(/(\d+)\/(\d+)/, '$2')}`

const monthsCode: { [index: number]: (year: string | number) => string } = {
  1: (year) => `Jan-${year}`,
  2: (year) => `Fev-${year}`,
  3: (year) => `Mar-${year}`,
  4: (year) => `Abr-${year}`,
  5: (year) => `Mai-${year}`,
  6: (year) => `Jun-${year}`,
  7: (year) => `Jul-${year}`,
  8: (year) => `Ago-${year}`,
  9: (year) => `Set-${year}`,
  10: (year) => `Out-${year}`,
  11: (year) => `Nov-${year}`,
  12: (year) => `Dez-${year}`
}

export const monthFormatter = (value: string): string => monthsCode[
  Number(value.replace(/(\d+)-(\d)/gi, '$2'))
](value.split('-')[0].replace(/(\d{1,2})(\d{1,2})/gi, '$2'))
