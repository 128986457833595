import React, { FC } from 'react';
import { Container } from './styles'

export const AdditionalInfo: FC = () => {
    return (
        <Container>
            <p>
                Para mais informações sobre Métricas de Sistema, Componentes e Históricos de Incidentes, acesse o <a href="http://status.directory.opinbrasil.com.br/" target="_blank">Status do Diretório.</a>
            </p>
        </Container>
    )
}