import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import React, {FC, useState} from 'react';
import {FaMinus} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {SelectDefaultValue} from 'protocols';
import {
  checkInstitutionsListGraphsStatus,
  parseNumberToFixedTwo,
  parseNumberToLocale,
} from 'utils';
import {
  ChartContainer,
  Container,
  Legend,
  NoData,
  ScrollInfo,
  TooltipContainer,
} from './styles';
interface PercentLabelProps {
  x: number;
  y: number;
  width: number;
  value: number;
}
const PercentLabel: any = ({x, y, width, value}: PercentLabelProps) => (
  <g>
    <text
      x={x + width + 30}
      y={y + 10}
      fill={theme.palette.common.black}
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {parseNumberToLocale(parseNumberToFixedTwo(value.toString()), 2)}%
    </text>
  </g>
);

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Instituição: {payload[0].payload.serverName}</span>
        <span>
          Média:{' '}
          {parseNumberToLocale(
            parseNumberToFixedTwo(payload[0].value.toString()),
            2,
          )}
          %
        </span>
        <span>
          SLA:{' '}
          {parseNumberToLocale(
            parseNumberToFixedTwo(payload[1].value.toString()),
            2,
          )}
          %
        </span>
      </TooltipContainer>
    );
  }
  return null;
};
interface AverageAvailabilityPageTwoProps {
  data: any;
  chartOrder: SelectDefaultValue;
  mobile: boolean;
  loading: boolean;
}
const AverageAvailabilityPageTwo: FC<AverageAvailabilityPageTwoProps> = ({
  data,
  chartOrder,
  mobile,
  loading,
}) => {
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const filteredData = data?.filter((item: any) => item.uptime > 0);
  const status = checkInstitutionsListGraphsStatus(data, loading, filteredData);

  return (
    <>
      {status === 'LOADING' && (
        <Skeleton variant='rect' width={'80%'} height={500} />
      )}

      {status === 'NODATA' && <NoData>Nenhum dado encontrado</NoData>}

      {status === 'HASDATA' && (
        <Container>
          <ChartContainer onScroll={() => setShowScrollInfo(false)}>
            <ResponsiveContainer
              minWidth={500}
              minHeight={data.length > 17 ? data.length * 60 : 100}
            >
              <ComposedChart
                layout='vertical'
                barCategoryGap='20%'
                data={_.orderBy(
                  filteredData.map((item: any) => ({...item, line: 95})),
                  [chartOrder.value.by],
                  [chartOrder.value.type],
                )}
              >
                <XAxis type='number' hide={true} domain={[0, 120]} />
                <YAxis
                  dataKey='serverName'
                  type='category'
                  width={180}
                  tickLine={false}
                  interval={0}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey='uptime'
                  fill={theme.palette.primary.light}
                  barSize={15}
                >
                  <LabelList
                    dataKey='uptime'
                    position='right'
                    content={PercentLabel}
                  />
                </Bar>
                <Line
                  isAnimationActive={false}
                  type='basis'
                  dot={false}
                  dataKey='line'
                  stroke={theme.palette.warning.main}
                  strokeWidth={3}
                  strokeDasharray='10'
                />
                {!mobile && <Tooltip content={<CustomTooltip />} />}
              </ComposedChart>
            </ResponsiveContainer>
            <ScrollInfo showScrollInfo={showScrollInfo}>
              <p>
                Pressione e arraste para a esquerda para visualizar o restante
                do gráfico
              </p>
            </ScrollInfo>
          </ChartContainer>

          <Legend>
            <span>
              <FaMinus color={theme.palette.warning.main} />
              <FaMinus color={theme.palette.warning.main} />
            </span>
            <span>
              Disponibilidade média esperada para APIs segundo Instrução
              Normativa n°98: SLA: 95,0% do tempo a cada 24 horas
            </span>
          </Legend>
        </Container>
      )}
    </>
  );
};

export default AverageAvailabilityPageTwo;
