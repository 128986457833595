import {Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import Select from 'react-select';
import {theme} from 'theme/theme';
import {DateValues} from '../../protocols';
import {getMirrorReport, SubmitParams} from '../../services/mirrorReport';
import FilterBar from '../FilterBar';
import {
  ErrorsCallsPageOne,
  ErrorsCallsPageTwo,
  RejectedCallsPageOne,
  RejectedCallsPageTwo,
} from './graphs';
import {
  errorsOrgsOrderDefault,
  errorsPercentageDataToOrderBy,
  errorsPercentageOrderDefault,
  errorsPercentageOrderOptions,
  errrosOrgsOrderOptions,
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  orgsOrderOptions,
  percentageDataToOrderBy,
  percentageOrderDefault,
  percentageOrderOptions,
} from './helpers';
import {
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
  OrderSelectContainer,
} from './styles';
import {RejectedCallsProps} from './types';

const useStyles = makeStyles(() => ({
  tabsRoot: {
    maxWidth: 400,
    minWidth: 200,
    flex: 1,
  },
  tabLabelRoot: {
    color: theme.palette.secondary.main,
    fontSize: '1em',
    padding: '2rem 1rem',
    textTransform: 'none',
  },
  tabLabelSelected: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
}));

const RejectedCalls: FC<RejectedCallsProps> = ({orgsData, mobile}) => {
  const classes = useStyles();

  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [currentKey, setCurrentKey] = useState(0);

  const [submitChartOne, setSubmitChartOne] = useState<
    DateValues | undefined
  >();
  const [submitChartTwo, setSubmitChartTwo] = useState<
    DateValues | undefined
  >();
  const [submitChartThree, setSubmitChartThree] = useState<
    DateValues | undefined
  >();
  const [submitChartFour, setSubmitChartFour] = useState<
    DateValues | undefined
  >();

  const [pageOneData, setPageOneData] = useState<any>();
  const [pageTwoData, setPageTwoData] = useState<any>();
  const [pageThreeData, setPageThreeData] = useState<any>();
  const [pageFourData, setPageFourData] = useState<any>();

  const [firstChartOrder, setFirstChartOrder] = useState(
    percentageOrderDefault(),
  );
  const [secondChartOrder, setSecondChartOrder] = useState(orgsOrderDefault());
  const [thirdChartOrder, setThirdChartOrder] = useState(
    errorsPercentageOrderDefault(),
  );
  const [fourthChartOrder, setFourthChartOrder] = useState(
    errorsOrgsOrderDefault(),
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, []);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, [currentKey]);

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey);
  };

  const selectChange = (option: any): void => {
    const orderList: any = {
      0: setFirstChartOrder,
      1: setSecondChartOrder,
      2: setThirdChartOrder,
      3: setFourthChartOrder,
    };

    if (currentKey === 0) {
      return orderList[currentKey](option || percentageOrderOptions[0]);
    }
    if (currentKey === 1) {
      return orderList[currentKey](
        option || orgsOrderOptions[orgsOrderOptions.length - 1],
      );
    }
    if (currentKey === 2) {
      return orderList[currentKey](option || errorsPercentageOrderOptions[0]);
    }
    if (currentKey === 3) {
      return orderList[currentKey](
        option || errrosOrgsOrderOptions[errrosOrgsOrderOptions.length - 1],
      );
    }
  };

  const currentSelectedFilter = (): any => {
    return currentKey === 0
      ? firstChartOrder
      : currentKey === 1
      ? secondChartOrder
      : currentKey === 2
      ? thirdChartOrder
      : fourthChartOrder;
  };

  const makeChartData: {[index: number]: () => void} = {
    0: () => {
      if (submitChartOne) {
        const {firstDate, lastDate, servers} = submitChartOne;

        getChartData(
          {firstDate, lastDate, servers, type: 'totalRejected'},
          data =>
            setPageOneData(
              percentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            ),
        );
      }
    },
    1: () => {
      if (submitChartTwo) {
        const {firstDate, lastDate, servers} = submitChartTwo;

        getChartData(
          {firstDate, lastDate, servers, type: 'rejectedByServer'},
          data => setPageTwoData(data),
        );
      }
    },
    2: () => {
      if (submitChartThree) {
        const {firstDate, lastDate, servers} = submitChartThree;

        getChartData(
          {firstDate, lastDate, servers, type: 'errorsCalls'},
          data =>
            setPageThreeData(
              errorsPercentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            ),
        );
      }
    },
    3: () => {
      if (submitChartFour) {
        const {firstDate, lastDate, servers} = submitChartFour;

        getChartData(
          {firstDate, lastDate, servers, type: 'errorsCallsByServer'},
          data => setPageFourData(data),
        );
      }
    },
  };
  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true);
      getMirrorReport({...dateData}).then(
        (data: any) => {
          setChart(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitChartOne, submitChartTwo, submitChartThree, submitChartFour],
  );

  useEffect(() => {
    makeChartData[currentKey]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitChartOne, submitChartTwo, submitChartThree, submitChartFour]);

  const getDataToExportCSV = (): any => {
    if (pageOneData && currentKey === 0) {
      return pageOneData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageTwoData && currentKey === 1) {
      return pageTwoData.map(({serverName, rejections}: any): any => ({
        instituicao: serverName,
        valor: rejections,
      }));
    }
    if (pageThreeData && currentKey === 2) {
      return pageThreeData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageFourData && currentKey === 3) {
      return pageFourData.map(({serverName, errors}: any): any => ({
        instituicao: serverName,
        valor: errors,
      }));
    }
    return '';
  };

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={
          currentKey === 0 || currentKey === 1
            ? 'chamadas-rejeitadas-api.csv'
            : 'chamadas-fracassadas-api.csv'
        }
        style={{
          display: !mobile ? 'none' : 'block',
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      >
        <h3>
          <FaSave color={theme.palette.secondary.main} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Chamadas Rejeitadas das APIs</GraphTitle>
          {currentKey <= 1 ? (
            <GraphSubtitle>
              Demonstração do número de chamadas rejeitadas das APIs de
              instituições participantes
            </GraphSubtitle>
          ) : (
            <GraphSubtitle>
              Demonstração do número de chamadas fracassadas das APIs de
              instituições participantes
            </GraphSubtitle>
          )}
        </div>

        {currentKey === 0 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartOne}
          />
        )}
        {currentKey === 1 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartTwo}
          />
        )}
        {currentKey === 2 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartThree}
          />
        )}
        {currentKey === 3 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartFour}
          />
        )}

        <CSVLink
          data={getDataToExportCSV()}
          filename={
            currentKey === 0 || currentKey === 1
              ? 'chamadas-rejeitadas-api.csv'
              : 'chamadas-fracassadas-api.csv'
          }
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      <OrderSelectContainer>
        <span>Classifique por:</span>
        <Select
          options={selectOptions}
          value={currentSelectedFilter()}
          onChange={selectChange}
        />
      </OrderSelectContainer>

      <GraphContainer>
        <Tabs
          orientation='vertical'
          variant='fullWidth'
          value={currentKey}
          onChange={handleTabs}
          className={classes.tabsRoot}
          TabIndicatorProps={{style: {display: 'none'}}}
        >
          {graphListHelper.map(item => (
            <Tab
              key={`${item.title}-${item.key}`}
              classes={{
                root: classes.tabLabelRoot,
                selected: classes.tabLabelSelected,
              }}
              label={item.title}
              wrapped
              value={item.key}
            />
          ))}
        </Tabs>
        {currentKey === 0 && (
          <RejectedCallsPageOne
            data={pageOneData}
            chartOrder={firstChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 1 && (
          <RejectedCallsPageTwo
            data={pageTwoData}
            chartOrder={secondChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 2 && (
          <ErrorsCallsPageOne
            data={pageThreeData}
            chartOrder={thirdChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 3 && (
          <ErrorsCallsPageTwo
            data={pageFourData}
            chartOrder={fourthChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
      </GraphContainer>

      {currentKey === 0 || currentKey === 2 ? (
        <footer>
          <div>Quantidade de Instituições participantes</div>
        </footer>
      ) : (
        <footer>
          <div>
            Apenas instituições que reportaram Chamadas Rejeitadas das APIs no
            período selecionado são exibidas.
          </div>
        </footer>
      )}
    </Container>
  );
};

export default RejectedCalls;
