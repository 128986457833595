import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 400px;
  flex: 3;
`

export const TooltipContainer = styled.div`
  background-color: #3c3c3c;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  padding: 1rem;
`

export const NoData = styled.div`
  background-color: #ececec;
  border-radius: 5px;
  width: 80%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
`
