import Skeleton from '@material-ui/lab/Skeleton';
import {endOfMonth, getTime, startOfMonth, subMonths} from 'date-fns';
import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import totalIcon from 'assets/icons/total-line-black-blue.png';
import {DateValuesOnlyWithDatePickers} from 'protocols';
import {sysAidGetAtendimentosRealizados} from 'services/sysAidRequests';
import FilterBar from '../../../FilterBarOnlyWithDatePicker';
import {makeChartData} from './helpers/makeChartData';
import {
  ChartContainer,
  Container,
  ErrorContainer,
  InfoFooterContainer,
  LegendContainer,
  LegendItem,
  ScrollInfo,
  TooltipContainer,
  TotalLegendItem,
} from './styles';

interface CustomBarLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}
const CustomBarLabel: any = ({
  x,
  y,
  width,
  height,
  value,
}: CustomBarLabelProps) => (
  <g>
    <text
      x={x + width / 2}
      y={y - 10}
      fill='#000'
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {value}
    </text>
  </g>
);

const renderLegend = (props: any) => {
  const {payload} = props;

  return (
    <LegendContainer>
      {payload.map((entry: any, index: any): any => {
        return entry.value !== 'Total' ? (
          <LegendItem key={`item-${index}`} iconColor={entry.payload.fill}>
            <div></div>
            <p>
              {entry.value === 'Solicitação de Informações'
                ? 'Informações'
                : entry.value === 'Solicitação de acesso'
                ? 'Acesso'
                : entry.value === 'Solicitação de Melhorias'
                ? 'Melhorias'
                : entry.value}
            </p>
          </LegendItem>
        ) : (
          <TotalLegendItem key={`item-${index}`}>
            <div>
              <img src={totalIcon} />
            </div>
            <p>Total</p>
          </TotalLegendItem>
        );
      })}
    </LegendContainer>
  );
};

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Período: {payload[0].payload.mes}</span>
        {payload.map((item: any) => (
          <span key={item.dataKey}>
            {item.name === 'Solicitação de Informações'
              ? 'Informações'
              : item.name === 'Solicitação de acesso'
              ? 'Acesso'
              : item.name}
            : {item.value}
          </span>
        ))}
      </TooltipContainer>
    );
  }
  return null;
};

const CustomLineLabel = ({x, y, stroke, value}: any): any => {
  return (
    <text x={x} y={y} dy={-6} fill={stroke} textAnchor='middle'>
      {value}
    </text>
  );
};

const CustomizedDot = (props: any): any => {
  const {cx, cy} = props;

  return <circle cx={cx} cy={cy} r='4' fill={theme.palette.primary.main} />;
};

const CustomizedActiveDot = (props: any): any => {
  const {cx, cy} = props;

  return <circle cx={cx} cy={cy} r='4' fill={theme.palette.primary.main} />;
};

const barKeys = [
  {id: 0, key: 'Solicitação de acesso', fill: theme.palette.primary.main},
  {id: 1, key: 'Incidentes', fill: theme.palette.secondary.light},
  {id: 2, key: 'Solicitação de Informações', fill: theme.palette.primary.light},
  {id: 3, key: 'Solicitação de Melhorias', fill: theme.palette.warning.main},
  {id: 4, key: 'Cancelado', fill: theme.palette.secondary.main},
];
interface AtendimentosRealizadosProps {
  mobile: boolean;
}
const AtendimentosRealizados: FC<AtendimentosRealizadosProps> = ({mobile}) => {
  const [data, setData] = useState<any[] | undefined>();
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const [dateToFilter, setDateToFilter] =
    useState<DateValuesOnlyWithDatePickers>({
      firstDate: startOfMonth(subMonths(new Date(), 1)),
      lastDate: endOfMonth(subMonths(new Date(), 1)),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const getData = (): void => {
    if (dateToFilter) {
      setLoading(true);
      sysAidGetAtendimentosRealizados([
        `${getTime(dateToFilter.firstDate)}`,
        `${getTime(dateToFilter.lastDate)}`,
      ])
        .then(response => {
          if (!response.data) throw Error('Erro na obtenção dos dados');
          if (Object.keys(response.data).length === 0)
            throw Error('Nenhum dado encontrado');

          setData(makeChartData(response.data));
          setLoading(false);
          setError({status: false, message: ''});
        })
        .catch(err => {
          setData(undefined);
          setLoading(false);
          setError({status: true, message: err.message});
        });
    }
  };

  useEffect(() => {
    getData();
  }, [dateToFilter]);

  const getDataToExportCSV = (): any => {
    if (data) {
      return data.map(({Total, mes, ...rest}: any): any => ({
        mes,
        ...rest,
        Total,
      }));
    }
    return '';
  };

  return (
    <>
      {!data && loading ? (
        <Container>
          <header>
            <div>
              <h1>Atendimentos Realizados no Service Desk</h1>
              <p>Em número de chamados</p>
            </div>
            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />
          </header>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </Container>
      ) : (
        <Container>
          <CSVLink
            data={getDataToExportCSV()}
            filename={'atendimentos-realizados.csv'}
            style={{
              display: !mobile ? 'none' : 'block',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <h3>
              <FaSave color={theme.palette.secondary.main} />
            </h3>
          </CSVLink>

          <header>
            <div>
              <h1>Atendimentos Realizados no Service Desk</h1>
              <p>Em número de chamados</p>
            </div>

            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />

            <CSVLink
              data={getDataToExportCSV()}
              filename={'atendimentos-realizados.csv'}
              style={{display: mobile ? 'none' : 'block'}}
            >
              <h3>
                <FaSave color={theme.palette.secondary.main} />
              </h3>
            </CSVLink>
          </header>

          {error.status && (
            <ErrorContainer>
              {/* <h3>Ops! Algo deu errado.</h3>
              <p>Tente novamento mais tarde.</p> */}
              <p>{error.message}</p>
            </ErrorContainer>
          )}

          {loading && !error.status ? (
            <div style={{width: '100%', margin: 'auto'}}>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </div>
          ) : (
            !error.status && (
              <>
                <ChartContainer onScroll={() => setShowScrollInfo(false)}>
                  <ResponsiveContainer minHeight={300} minWidth={500}>
                    <ComposedChart data={data}>
                      <XAxis dataKey='mes' type='category' dy={5} />
                      <YAxis
                        type='number'
                        padding={{top: 20}}
                        tickLine={false}
                      />
                      {_.orderBy(barKeys, ['id']).map(
                        (type: any, index: number) => (
                          <Bar
                            key={`${type.key}-${index}`}
                            isAnimationActive={false}
                            dataKey={type.key}
                            fill={type.fill}
                          >
                            <LabelList
                              dataKey={type.key}
                              position='top'
                              content={CustomBarLabel}
                            />
                          </Bar>
                        ),
                      )}
                      <Line
                        type='monotone'
                        dataKey='Total'
                        stroke={theme.palette.primary.main}
                        dot={<CustomizedDot />}
                        activeDot={<CustomizedActiveDot />}
                      >
                        <LabelList
                          dataKey='Total'
                          position='top'
                          content={CustomLineLabel}
                        />
                      </Line>
                      <Legend content={renderLegend} />
                      {!mobile && <Tooltip content={<CustomTooltip />} />}
                    </ComposedChart>
                  </ResponsiveContainer>

                  <ScrollInfo showScrollInfo={showScrollInfo}>
                    <p>
                      Pressione e arraste para a esquerda para visualizar o
                      restante do gráfico
                    </p>
                  </ScrollInfo>
                </ChartContainer>
                <InfoFooterContainer>
                  <ul>
                    <li>
                      <b>Acesso:</b> chamados aberto para solicitação de
                      acessos.
                    </li>
                    <li>
                      <b>Incidentes:</b> chamados abertos para comunicação de
                      falhas ou degradação de qualidade em algum serviço.
                    </li>
                    <li>
                      <b>Informações:</b> chamados abertos para solicitação de
                      informações.
                    </li>
                    <li>
                      <b>Melhorias:</b> chamados aberto para indicação de
                      melhorias.
                    </li>
                    <li>
                      <b>Cancelado:</b> chamados cancelados.
                    </li>
                  </ul>
                </InfoFooterContainer>
              </>
            )
          )}
        </Container>
      )}
    </>
  );
};

export default AtendimentosRealizados;
