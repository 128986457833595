import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  & > header > a {
    @media (max-width: 425px) {
      margin-left: auto;
      margin-top: 1rem;
    }
  }

  & > footer {
    width: 100%;
    text-align: center;
  }
`;

export const GraphTitle = styled.h1`
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  font-weight: bold;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphContent = styled.div`
  margin: auto;
  width: 500px;
  height: 500px;
  position: relative;

  & > h1 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  @media (max-width: 425px) {
    width: 400px;
    height: 400px;

    & > canvas {
      width: 400px;
      height: 400px;
    }
  }

  @media (max-width: 375px) {
    width: 300px;
    height: 300px;

    & > h1 {
      font-size: 1em;
    }

    & > canvas {
      width: 300px;
      height: 300px;
    }
  }
`;

interface GraphDetailsProps {
  color: string;
  background: string;
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
}
export const GraphDetails = styled.div<GraphDetailsProps>`
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 1rem;
  border-radius: 10px;

  position: absolute;
  top: ${({ top }) => top || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  left: ${({ left }) => left || 'auto'};
  right: ${({ right }) => right || 'auto'};

  @media (max-width: 1024px) {
    left: calc(${({ left }) => left || 'auto'} + 5%);
    right: calc(${({ right }) => right || 'auto'} + 5%);
  }

  @media (max-width: 768px) {
    max-width: 150px;
    left: calc(${({ left }) => left || 'auto'} + 10%);
    right: calc(${({ right }) => right || 'auto'} + 10%);
  }

  @media (max-width: 425px) {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }

  & > h1 {
    color: ${({ color }) => color};
    font-weight: bolder;
    font-size: 2rem;
  }

  & > h1,
  p {
    text-align: center;
    margin: 0;
  }
`;

export const GraphContainer = styled.div`
  width: 100%;
  min-height: 580px;
  position: relative;

  @media (max-width: 425px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;

export const FooterContainer = styled.div`
  text-align: left;
  padding: 0.5rem;
  font-size: 14px;

  & > p {
    margin: 0;
  }
`;

export const NoData = styled.div`
  width: 100%;
  min-height: 580px;
  position: relative;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`
