import Skeleton from '@material-ui/lab/Skeleton';
import {FC, useCallback, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {DateValues, OrgProps} from 'protocols';
import {getMirrorReport, SubmitParams} from 'services/mirrorReport';
import {checkValueNullOrUndefined, numberDecimalFormat} from 'utils';
import FilterBar from '../FilterBar';
import {
  Container,
  FooterContainer,
  GraphContainer,
  GraphContent,
  GraphDetails,
  GraphTitle,
  NoData,
} from './styles';
import {theme} from 'theme/theme';
interface KeyPerformancedataProps {
  orgsData: OrgProps[];
  mobile: boolean;
}
interface KeyPerformancedataData {
  averageAvailability: number;
  averageResponseTime: number;
  rejectedCalls: number;
  successfulApiCallsAbsolut: number;
  successfulApiCallsPorcent: number;
  rejectedCallsAbsolut: number;
}
const KeyPerformancedata: FC<KeyPerformancedataProps> = ({
  orgsData,
  mobile,
}) => {
  const [data, setData] = useState<KeyPerformancedataData | any | undefined>();
  const [submitValues, setSubmitValues] = useState<DateValues | undefined>();
  const [loading, setLoading] = useState(false);

  const makeCanvas = (): void => {
    const canvas: any = document.getElementById('key-performance-data-canvas');

    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cX = Math.floor(canvas.width / 2);
      const cY = Math.floor(canvas.height / 2);
      const radius = Math.min(cX, cY) * 0.75;
      let totalArc = 0.53;
      const data = [33.33, 33.33, 33.33];
      const colors = [
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.secondary.main,
      ];

      const drawWedge = (percent: number, color: string): void => {
        const arcRadians = ((percent / 100) * 360 * Math.PI) / 180;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(cX, cY);
        ctx.arc(cX, cY, radius, totalArc, totalArc + arcRadians, false);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
        ctx.restore();
        totalArc += arcRadians;
      };

      for (let i = 0; i < data.length; i++) {
        drawWedge(data[i], colors[i]);
      }

      // Círculo branco do meio
      ctx.beginPath();
      ctx.fillStyle = theme.palette.common.white;
      ctx.arc(cX, cY, radius * 0.75, 0, 2 * Math.PI, false);
      ctx.fill();
      ctx.closePath();

      // Linha 1
      ctx.beginPath();
      ctx.strokeStyle = theme.palette.common.white;
      ctx.moveTo(cX, cY);
      ctx.lineWidth = 2;
      ctx.lineTo(cX, 0);
      ctx.stroke();
      ctx.closePath();

      // Linha 2
      ctx.beginPath();
      ctx.strokeStyle = theme.palette.common.white;
      ctx.moveTo(cX, cY);
      ctx.lineWidth = 2;
      ctx.lineTo(0, canvas.height / 1.27);
      ctx.stroke();
      ctx.closePath();

      // Linha 3
      ctx.beginPath();
      ctx.strokeStyle = theme.palette.common.white;
      ctx.moveTo(cX, cY);
      ctx.lineWidth = 2;
      ctx.lineTo(canvas.width * 1.37, canvas.height);
      ctx.stroke();
      ctx.closePath();

      // Texto FALHAS
      ctx.save();
      ctx.translate(cX / 2.2, cY / 1.5);
      ctx.rotate((Math.PI / 180) * -60);
      ctx.strokeStyle = theme.palette.common.white;
      ctx.lineWidth = 1;
      ctx.fillStyle = theme.palette.common.white;
      ctx.font = '18px sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.strokeText('FALHAS', 0, 0);
      ctx.fillText('FALHAS', 0, 0);
      ctx.restore();

      // Texto RESPOSTA
      ctx.save();
      ctx.translate(cX * 1.56, cY / 1.4);
      ctx.rotate((Math.PI / 180) * 62);
      ctx.strokeStyle = theme.palette.secondary.contrastText;
      ctx.lineWidth = 1;
      ctx.fillStyle = theme.palette.secondary.contrastText;
      ctx.font = '18px sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.strokeText('RESPOSTA', 0, 0);
      ctx.fillText('RESPOSTA', 0, 0);
      ctx.restore();

      // Texto SUCESSO
      ctx.save();
      ctx.strokeStyle = theme.palette.common.white;
      ctx.lineWidth = 1;
      ctx.fillStyle = theme.palette.common.white;
      ctx.font = '18px sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.strokeText('SUCESSO', cX, cY * 1.65);
      ctx.fillText('SUCESSO', cX, cY * 1.65);
      ctx.restore();
    }
  };

  useEffect(() => {
    makeCanvas();
  }, [data]);

  const getDashData = useCallback(
    (dateData: SubmitParams): void => {
      getMirrorReport({...dateData, type: 'performanceMetrics'}).then(
        data => {
          setData(data);
        },
        () => setData(undefined),
      );
    },
    [submitValues],
  );

  useEffect(() => {
    if (submitValues) {
      const {firstDate, lastDate, servers} = submitValues;

      getDashData({firstDate, lastDate, servers});
    }
  }, [submitValues]);

  const getDataToExportCSV = (): any => {
    if (data) {
      return Object.keys(data).map(key => {
        if (key === 'rejectedCalls') {
          return {tipo: 'Chamadas de falhas das APIs (%)', valor: data[key]};
        }
        if (key === 'rejectedCallsAbsolut') {
          return {
            tipo: 'Chamadas de falhas das APIs (absoluto)',
            valor: data[key],
          };
        }
        if (key === 'averageAvailability') {
          return {tipo: 'Disponibilidade média das APIs (%)', valor: data[key]};
        }
        if (key === 'averageResponseTime') {
          return {
            tipo: 'Tempo médio de resposta das APIs (milissegundos)',
            valor: data[key],
          };
        }
        if (key === 'successfulApiCallsPorcent') {
          return {tipo: 'Chamadas de sucesso das APIs (%)', valor: data[key]};
        }
        return {
          tipo: 'Chamadas de sucesso das APIs (absoluto)',
          valor: data[key],
        };
      });
    }

    return '';
  };

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={'principais-metricas-performance.csv'}
        style={{display: !mobile ? 'none' : 'block', alignSelf: 'flex-end'}}
      >
        <h3>
          <FaSave color={theme.palette.common.black} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Panorama do Open Insurance</GraphTitle>
        </div>

        <FilterBar orgsData={orgsData || []} submitValues={setSubmitValues} />

        <CSVLink
          data={getDataToExportCSV()}
          filename={'principais-metricas-performance.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      {!data && <Skeleton variant='rect' width={'100%'} height={500} />}

      {data && Object.keys(data).length > 0 && (
        <>
          {data && !data.averageAvailability && (
            <>
              <NoData>Não há dados para o período selecionado.</NoData>
            </>
          )}

          {data && data.averageAvailability && (
            <GraphContainer>
              <GraphDetails
                color={theme.palette.primary.contrastText}
                background={theme.palette.primary.main}
                right='65%'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.rejectedCalls)
                    ? parseFloat(data?.rejectedCalls.toString())
                        .toFixed(2)
                        .replace('.', ',')
                    : 0}
                  %
                </h1>
                <p>Chamadas de falhas das APIs</p>
                <p>(%)</p>
              </GraphDetails>

              <GraphDetails
                color={theme.palette.primary.contrastText}
                background={theme.palette.primary.main}
                right='68%'
                top='28%'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.rejectedCallsAbsolut)
                    ? numberDecimalFormat(
                        Math.ceil(data?.rejectedCallsAbsolut),
                        1,
                      )
                    : 0}
                </h1>
                <p>Chamadas de falhas das APIs</p>
                <p>(absoluto)</p>
              </GraphDetails>

              <GraphDetails
                color={theme.palette.secondary.contrastText}
                background={theme.palette.secondary.main}
                left='63%'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.averageAvailability)
                    ? parseFloat(data?.averageAvailability.toString())
                        .toFixed(2)
                        .replace('.', ',')
                    : 0}
                  %
                </h1>
                <p>Disponibilidade média das APIs</p>
                <p>
                  (%)<sup>1</sup>
                </p>
              </GraphDetails>

              <GraphDetails
                color={theme.palette.secondary.contrastText}
                background={theme.palette.secondary.main}
                left='68%'
                top='28%'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.averageResponseTime)
                    ? Math.round(data?.averageResponseTime)
                    : 0}
                </h1>
                <p>Tempo médio de resposta das APIs</p>
                <p>
                  (milissegundos)<sup>2</sup>
                </p>
              </GraphDetails>

              <GraphDetails
                color={theme.palette.primary.contrastText}
                background={theme.palette.primary.light}
                left='55%'
                bottom='0'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.successfulApiCallsPorcent)
                    ? parseFloat(data?.successfulApiCallsPorcent.toString())
                        .toFixed(2)
                        .replace('.', ',')
                    : 0}
                  %
                </h1>
                <p>Chamadas de sucesso das APIs</p>
                <p>(%)</p>
              </GraphDetails>

              <GraphDetails
                color={theme.palette.primary.contrastText}
                background={theme.palette.primary.light}
                right='55%'
                bottom='0'
              >
                <h1>
                  {!checkValueNullOrUndefined(data?.successfulApiCallsAbsolut)
                    ? numberDecimalFormat(
                        Math.ceil(data?.successfulApiCallsAbsolut),
                        1,
                      )
                    : 0}
                </h1>
                <p>Chamadas de sucesso das APIs</p>
                <p>(absoluto)</p>
              </GraphDetails>

              <GraphContent>
                <h1>Principais métricas de performance</h1>

                <canvas
                  id='key-performance-data-canvas'
                  width='500'
                  height='500'
                ></canvas>
              </GraphContent>
            </GraphContainer>
          )}

          <FooterContainer>
            <p>
              1. A disponibilidade média das APIs deve satisfazer os seguintes
              requisitos mínimos: (i) 95,0% do tempo a cada 24 horas; e (ii)
              99,5% do tempo a cada 3 meses.
            </p>
            <p>
              2. O desempenho na resposta das APIs deverá manter o percentil 95
              dos seguintes tempos máximos de resposta: (i) 1000ms para APIs de
              alta prioridade; (ii) 1500ms para APIs de média prioridade; e
              (iii) 4000ms para APIs administrativas.
            </p>
          </FooterContainer>
        </>
      )}
    </Container>
  );
};

export default KeyPerformancedata;
