export const graphListHelper = [
  { key: 0, title: 'Tempo médio de resposta das APIs' },
  {
    key: 1,
    title: 'Tempo médio de resposta das APIs por instituição participante'
  },
  {

    key: 2,
    title: 'Tempo médio de resposta das APIs alta prioridade'
  },
  {

    key: 3,
    title: 'Tempo médio de resposta das APIs alta prioridade por instituição participante'
  },
  {
    key: 4,
    title: 'Tempo médio de resposta das APIs média prioridade'
  },
  {
    key: 5,
    title: 'Tempo médio de resposta das APIs média prioridade por instituição participante'
  }
]
