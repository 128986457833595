export const parseNumberToInt = (value: string | number): number => parseInt(value.toString())

export const parseNumberToLocale = (value: string | number, minDigits: number = 0): string => {
  if (typeof value === 'number') { return value.toLocaleString('pt-BR') }
  return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: minDigits })
}

export const parseNumberToFixedTwo = (value: string | number | undefined): string => {
 
  if (typeof value === 'string') { return parseFloat(value).toFixed(2) }
  if (typeof value === 'number') { return value.toFixed(2) }

  return "0.00"
}
