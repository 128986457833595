import { FC } from 'react'
import { HistoryParams } from '../../protocols'
import { HistoryAverageAvailability, HistoryAverageResponseTimes, HistorySuccessfulCalls } from './graphs'
import { Container } from './styles'

interface HistoryGraphsProps {
  data: HistoryParams | undefined
  mobile: boolean
}
const HistoryGraphs: FC<HistoryGraphsProps> = ({ data, mobile }) => {
  return (
    <Container>
      {data && <HistorySuccessfulCalls data={data.successfulCalls} mobile={mobile} />}
      {data && <HistoryAverageResponseTimes data={data.averageResponseTimes} mobile={mobile} />}
      {data && <HistoryAverageAvailability data={data.averageAvailability} mobile={mobile} />}
    </Container>
  )
}

export default HistoryGraphs
