import api from './api'

export const getHistoryReport = async (): Promise<any | undefined> => {
  try {
    const { data } = await api.get('/historyReport')
    return data
  } catch (error) {
    return undefined
  }
}
