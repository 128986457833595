import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;

    @media (max-width: 425px) {
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  & > header > div:first-child {
    flex: 1;
  }

  & > header > div > h1 {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: bold;
    margin: 0;

    @media (max-width: 425px) {
      text-align: center;
    }
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`;

export const TooltipContainer = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.palette.common.white};
  color: ${({theme}) => theme.palette.common.white};
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

interface ScrollInfoProps {
  showScrollInfo: boolean;
}
export const ScrollInfo = styled.div<ScrollInfoProps>`
  background-color: rgba(${({theme}) => theme.palette.common.black}, 0.5);
  color: ${({theme}) => theme.palette.common.white};
  display: none;
  text-align: center;
  font-size: 20px;

  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    display: ${({showScrollInfo}) => (showScrollInfo ? 'flex' : 'none')};
  }
`;

export const ErrorContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    color: red;
  }
`;

export const LegendContainer = styled.ul`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

interface LegendItemProps {
  iconColor: string | undefined | null;
}
export const LegendItem = styled.li<LegendItemProps>`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > div {
    background-color: ${({iconColor, theme}) =>
      iconColor || theme.common.black};
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  & > p {
    margin: 0;
  }
`;

export const InfoFooterContainer = styled.div`
  & > p {
    margin: 0;
  }
`;
