import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaMinus, FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {HistoryChildrenParams} from 'protocols';
import {parseNumberToFixedTwo, parseNumberToLocale} from 'utils';
import {monthFormatter} from 'utils/monthFormat';
import {
  ChartContainer,
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
  Legend,
  ScrollInfo,
  TooltipContainer,
} from './styles';

interface PercentLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}
const PercentLabel: any = ({x, y, width, height, value}: PercentLabelProps) => (
  <g>
    <text
      x={x + width / 2}
      y={y - 10}
      fill={theme.palette.common.black}
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {parseNumberToLocale(Number(parseNumberToFixedTwo(value.toString())))}%
    </text>
  </g>
);

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Período: {payload[0].payload.month_year}</span>
        <span>
          Porcentagem:{' '}
          {parseNumberToLocale(
            Number(parseNumberToFixedTwo(payload[0].value.toString())),
          )}
          %
        </span>
        <span>SLA: {payload[1].value}%</span>
      </TooltipContainer>
    );
  }
  return null;
};

interface HistoryAverageAvailabilityProps {
  data: HistoryChildrenParams[];
  mobile: boolean;
}
const HistoryAverageAvailability: FC<HistoryAverageAvailabilityProps> = ({
  data,
  mobile,
}) => {
  const [filteredData, setFilteredData] = useState<HistoryChildrenParams[]>([]);
  const [showScrollInfo, setShowScrollInfo] = useState(true);

  useEffect(() => {
    if (data) {
      const newData = [...data];
      setFilteredData(
        _.orderBy(
          newData.map(item => ({
            ...item,
            month_year: monthFormatter(item.month_year),
            line: 95,
            id: item.month_year.replace('-', ''),
          })),
          ['id'],
          ['asc'],
        ),
      );
    }
  }, []);

  const getDataToExportCSV = (): any => {
    return filteredData.map(({month_year: monthYear, value}: any): any => ({
      periodo: monthYear,
      porcentagem: value,
    }));
  };

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={'historico-disponibilidade-media-api.csv'}
        style={{
          display: !mobile ? 'none' : 'block',
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      >
        <h3>
          <FaSave color={theme.palette.secondary.main} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Disponibilidade Média das APIs</GraphTitle>
          <GraphSubtitle>
            Demonstração da disponibilidade média das APIs de instituições
            participantes
          </GraphSubtitle>
        </div>

        <CSVLink
          data={getDataToExportCSV()}
          filename={'historico-disponibilidade-media-api.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      <GraphContainer>
        <ChartContainer onScroll={() => setShowScrollInfo(false)}>
          <ResponsiveContainer minHeight={300} minWidth={500}>
            <ComposedChart data={filteredData}>
              <XAxis dataKey='month_year' type='category' />
              <YAxis
                type='number'
                domain={[0, 100]}
                padding={{top: 20}}
                tickLine={false}
              />
              <Bar
                isAnimationActive={false}
                dataKey='value'
                fill={theme.palette.primary.light}
              >
                <LabelList
                  dataKey='value'
                  position='top'
                  content={PercentLabel}
                />
              </Bar>
              <Line
                isAnimationActive={false}
                type='basis'
                dot={false}
                dataKey='line'
                stroke={theme.palette.warning.main}
                strokeWidth={3}
                strokeDasharray='10'
              />
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>

          <ScrollInfo showScrollInfo={showScrollInfo}>
            <p>
              Pressione e arraste para a esquerda para visualizar o restante do
              gráfico
            </p>
          </ScrollInfo>
        </ChartContainer>
      </GraphContainer>

      <Legend>
        <span>
          <FaMinus color={theme.palette.warning.main} />
          <FaMinus color={theme.palette.warning.main} />
        </span>
        <span>
          Disponibilidade média esperada para APIs segundo Instrução Normativa
          n°98: SLA: 95,0% do tempo a cada 24 horas
        </span>
      </Legend>
    </Container>
  );
};

export default HistoryAverageAvailability;
