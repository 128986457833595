import {Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import Select from 'react-select';
import {theme} from 'theme/theme';
import {DateValues} from 'protocols';
import {getMirrorReport, SubmitParams} from 'services/mirrorReport';
import FilterBar from '../FilterBar';
import {
  AverageResponseTimePageFive,
  AverageResponseTimePageFour,
  AverageResponseTimePageOne,
  AverageResponseTimePageSix,
  AverageResponseTimePageThree,
  AverageResponseTimePageTwo,
} from './graphs';
import {
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  orgsOrderOptions,
  percentageDataToOrderBy,
  percentageOrderDefault,
  percentageOrderOptions,
} from './helpers';
import {
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
  OrderSelectContainer,
} from './styles';
import {AverageResponseTimesProps} from './types';

const useStyles = makeStyles(() => ({
  tabsRoot: {
    maxWidth: 400,
    minWidth: 200,
    flex: 1,
  },
  tabLabelRoot: {
    color: theme.palette.secondary.main,
    fontSize: '1em',
    padding: '2rem 1rem',
    textTransform: 'none',
  },
  tabLabelSelected: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
}));

const AverageResponseTime: FC<AverageResponseTimesProps> = ({
  orgsData,
  mobile,
}) => {
  const classes = useStyles();
  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [currentKey, setCurrentKey] = useState(0);

  const [submitChartOne, setSubmitChartOne] = useState<
    DateValues | undefined
  >();
  const [submitChartTwo, setSubmitChartTwo] = useState<
    DateValues | undefined
  >();
  const [submitChartThree, setSubmitChartThree] = useState<
    DateValues | undefined
  >();
  const [submitChartFour, setSubmitChartFour] = useState<
    DateValues | undefined
  >();
  const [submitChartFive, setSubmitChartFive] = useState<
    DateValues | undefined
  >();
  const [submitChartSix, setSubmitChartSix] = useState<
    DateValues | undefined
  >();

  const [pageOneData, setPageOneData] = useState<any>();
  const [pageTwoData, setPageTwoData] = useState<any>();
  const [pageThreeData, setPageThreeData] = useState<any>();
  const [pageFourData, setPageFourData] = useState<any>();
  const [pageFiveData, setPageFiveData] = useState<any>();
  const [pageSixData, setPageSixData] = useState<any>();

  const [averageTotalResponse, setAverageTotalResponse] = useState(0);
  const [averageTotalHighPriority, setAverageTotalHighPriority] = useState(0);
  const [averageTotalMediumPriority, setAverageTotalMediumPriority] =
    useState(0);

  const [firstChartOrder, setFirstChartOrder] = useState(
    percentageOrderDefault(),
  );
  const [secondChartOrder, setSecondChartOrder] = useState(orgsOrderDefault());
  const [thirdChartOrder, setThirdChartOrder] = useState(
    percentageOrderDefault(),
  );
  const [fourthChartOrder, setFourthChartOrder] = useState(orgsOrderDefault());
  const [fifthChartOrder, setFifthChartOrder] = useState(
    percentageOrderDefault(),
  );
  const [sixthChartOrder, setSixthChartOrder] = useState(orgsOrderDefault());

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, []);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, [currentKey]);

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey);
  };

  const selectChange = (option: any): void => {
    const orderList: any = {
      0: setFirstChartOrder,
      1: setSecondChartOrder,
      2: setThirdChartOrder,
      3: setFourthChartOrder,
      4: setFifthChartOrder,
      5: setSixthChartOrder,
    };

    if (currentKey === 0 || currentKey === 2 || currentKey === 4) {
      return orderList[currentKey](option || percentageOrderOptions[0]);
    }

    return orderList[currentKey](
      option || orgsOrderOptions[orgsOrderOptions.length - 1],
    );
  };

  const currentSelectedFilter = (): any => {
    return currentKey === 0
      ? firstChartOrder
      : currentKey === 1
      ? secondChartOrder
      : currentKey === 2
      ? thirdChartOrder
      : currentKey === 3
      ? fourthChartOrder
      : currentKey === 4
      ? fifthChartOrder
      : sixthChartOrder;
  };

  const getDataToExportCSV = (): any => {
    if (pageOneData && currentKey === 0) {
      return pageOneData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageTwoData && currentKey === 1) {
      return pageTwoData.map(({serverName, response}: any): any => ({
        instituicao: serverName,
        valor: response,
      }));
    }
    if (pageThreeData && currentKey === 2) {
      return pageThreeData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageFourData && currentKey === 3) {
      return pageFourData.map(({serverName, response}: any): any => ({
        instituicao: serverName,
        valor: response,
      }));
    }
    if (pageFiveData && currentKey === 4) {
      return pageFiveData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageSixData && currentKey === 5) {
      return pageSixData.map(({serverName, response}: any): any => ({
        instituicao: serverName,
        valor: response,
      }));
    }
    return '';
  };

  const makeChartData: {[index: number]: () => void} = {
    0: () => {
      if (submitChartOne) {
        const {firstDate, lastDate, servers} = submitChartOne;

        getChartData(
          {firstDate, lastDate, servers, type: 'averageResponse'},
          data => {
            setPageOneData(
              percentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            );
            setAverageTotalResponse(data.averageTotalResponse);
          },
        );
      }
    },
    1: () => {
      if (submitChartTwo) {
        const {firstDate, lastDate, servers} = submitChartTwo;

        getChartData(
          {firstDate, lastDate, servers, type: 'averageResponseByServer'},
          data => setPageTwoData(data),
        );
      }
    },
    2: () => {
      if (submitChartThree) {
        const {firstDate, lastDate, servers} = submitChartThree;

        getChartData(
          {firstDate, lastDate, servers, type: 'highPriority'},
          data => {
            setPageThreeData(
              percentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            );
            setAverageTotalHighPriority(data.averageTotalHighPriority);
          },
        );
      }
    },
    3: () => {
      if (submitChartFour) {
        const {firstDate, lastDate, servers} = submitChartFour;

        getChartData(
          {firstDate, lastDate, servers, type: 'highPriorityByServer'},
          data => setPageFourData(data),
        );
      }
    },
    4: () => {
      if (submitChartFive) {
        const {firstDate, lastDate, servers} = submitChartFive;

        getChartData(
          {firstDate, lastDate, servers, type: 'mediumPriority'},
          data => {
            setPageFiveData(
              percentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            );
            setAverageTotalMediumPriority(data.averageTotalMediumPriority);
          },
        );
      }
    },
    5: () => {
      if (submitChartSix) {
        const {firstDate, lastDate, servers} = submitChartSix;

        getChartData(
          {firstDate, lastDate, servers, type: 'mediumPriorityByServer'},
          data => setPageSixData(data),
        );
      }
    },
  };
  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true);
      getMirrorReport({...dateData}).then(
        (data: any) => {
          setChart(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [
      submitChartOne,
      submitChartTwo,
      submitChartThree,
      submitChartFour,
      submitChartFive,
      submitChartSix,
    ],
  );

  useEffect(() => {
    makeChartData[currentKey]();
  }, [
    submitChartOne,
    submitChartTwo,
    submitChartThree,
    submitChartFour,
    submitChartFive,
    submitChartSix,
  ]);

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={'tempo-medio-resposta-api.csv'}
        style={{
          display: !mobile ? 'none' : 'block',
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      >
        <h3>
          <FaSave color={theme.palette.secondary.main} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Tempo Médio de Resposta das APIs</GraphTitle>
          <GraphSubtitle>
            Demonstração do tempo médio de resposta APIs de instituições
            participantes
          </GraphSubtitle>
        </div>

        {currentKey === 0 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartOne}
          />
        )}
        {currentKey === 1 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartTwo}
          />
        )}
        {currentKey === 2 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartThree}
          />
        )}
        {currentKey === 3 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartFour}
          />
        )}
        {currentKey === 4 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartFive}
          />
        )}
        {currentKey === 5 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartSix}
          />
        )}

        <CSVLink
          data={getDataToExportCSV()}
          filename={'tempo-medio-resposta-api.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      <OrderSelectContainer>
        <span>Classifique por:</span>
        <Select
          options={selectOptions}
          value={currentSelectedFilter()}
          onChange={selectChange}
        />
      </OrderSelectContainer>

      <GraphContainer>
        <Tabs
          orientation='vertical'
          variant='fullWidth'
          value={currentKey}
          onChange={handleTabs}
          className={classes.tabsRoot}
          TabIndicatorProps={{style: {display: 'none'}}}
        >
          {graphListHelper.map(item => (
            <Tab
              key={`${item.title}-${item.key}`}
              classes={{
                root: classes.tabLabelRoot,
                selected: classes.tabLabelSelected,
              }}
              label={item.title}
              wrapped
              value={item.key}
            />
          ))}
        </Tabs>
        {currentKey === 0 && (
          <AverageResponseTimePageOne
            data={pageOneData}
            chartOrder={firstChartOrder}
            averageTotalResponse={averageTotalResponse}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 1 && (
          <AverageResponseTimePageTwo
            data={pageTwoData}
            chartOrder={secondChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 2 && (
          <AverageResponseTimePageThree
            data={pageThreeData}
            chartOrder={thirdChartOrder}
            averageTotalHighPriority={averageTotalHighPriority}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 3 && (
          <AverageResponseTimePageFour
            data={pageFourData}
            chartOrder={fourthChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 4 && (
          <AverageResponseTimePageFive
            data={pageFiveData}
            chartOrder={fifthChartOrder}
            averageTotalMediumPriority={averageTotalMediumPriority}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 5 && (
          <AverageResponseTimePageSix
            data={pageSixData}
            chartOrder={sixthChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
      </GraphContainer>

      {currentKey === 0 || currentKey === 2 || currentKey === 4 ? (
        <footer>
          <div>Quantidade de Instituições participantes</div>
        </footer>
      ) : (
        <footer>
          <div>
            Apenas instituições que reportaram Tempo Médio de Resposta das APIs
            no período selecionado são exibidas.
          </div>
        </footer>
      )}
    </Container>
  );
};

export default AverageResponseTime;
