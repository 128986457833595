import styled from 'styled-components';

export const Container = styled.div`
  .info-footer {
    background-color: ${({theme}) => theme.palette.common.white};
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: ${({theme}) => theme.palette.primary.main};
    padding: 0.8rem;

    display: flex;
    justify-content: center;
    align-items: center;

    > p {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({theme}) => theme.palette.primary.main};
  width: 100%;

  .wrapper-img {
    padding: 3rem 0;
    flex: 1;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    > img {
      width: 250px;
    }

    > .footer-menu-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;

      > a {
        color: ${({theme}) => theme.palette.common.white};
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;

    .wrapper-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
