import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  border-radius: 20px;
  padding: 1rem;
  margin: auto;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
  }

  & > header > div:first-child {
    flex: 1;
  }

  & > header > div > h1 {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: bold;
    text-align: left;
    margin: 0;

    @media (max-width: 425px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .MuiTabs-flexContainer {
      flex: 1;
      flex-direction: column;

      .MuiTab-root {
        max-width: 100%;
        justify-content: center;
      }
    }
  }
`;

export const GraphTitle = styled.h3`
  color: ${({theme}) => theme.palette.primary.main};
  margin: 1rem 0;
  text-align: center;
`;
export const ChartLegendContainer = styled.div`
  text-align: center;
`;
export const Graph = styled.div`
  width: 100%;
  overflow-y: scroll;
  flex: 3;

  position: relative;

  @media (max-width: 768px) {
    overflow-x: auto;
    overflow-y: hidden;

    > div:first-child {
      min-width: 300%;
    }
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.palette.common.white};
  color: ${({theme}) => theme.palette.common.white};
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

interface ScrollInfoProps {
  showScrollInfo: boolean;
}
export const ScrollInfo = styled.div<ScrollInfoProps>`
  background-color: rgba(0, 0, 0, 0.5);
  color: ${({theme}) => theme.palette.common.white};
  display: none;
  text-align: center;
  font-size: 20px;

  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    display: ${({showScrollInfo}) => (showScrollInfo ? 'flex' : 'none')};
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const TotalContainer = styled.div`
  background-color: ${({theme}) => theme.palette.primary.light};
  border-radius: 100px 30px 30px 100px;
  padding: 1rem;
  text-align: center;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h3 {
    color: ${({theme}) => theme.palette.common.white};
    font-weight: bold;
    margin: 0;
  }
`;

export const LegendContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

interface LegendItemProps {
  iconColor: string | undefined | null;
}
export const LegendItem = styled.li<LegendItemProps>`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > div {
    background-color: ${({iconColor}) => iconColor || '#000'};
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  & > p {
    margin: 0;
  }
`;

export const SkeletonsContainer = styled.div`
  margin: auto;
  width: 90%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
