import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';
import { FC } from 'react';
import { theme } from 'theme/theme';

import {
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
} from './styles';

function createData(
  org: string,
  period: string,
  date: string,
) {
  return { org, period, date };
}

const rows = [
  createData('Bradesco Seguros', '01/04/2022 à 30/08/2022', '10/03/2023'),
];

const HistoryDataChanged: FC = () => {

  return (
    <Container>
      <header>
        <div>
          <GraphTitle>Histórico de alterações</GraphTitle>
          <GraphSubtitle>
            Histórico de alterações de dados solicitados pelas sociedades participantes
          </GraphSubtitle>
        </div>
      </header>

      <GraphContainer>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Organização</TableCell>
                <TableCell style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Período alterado</TableCell>
                <TableCell style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Data alteração</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.date}>
                  <TableCell> {row.org} </TableCell>
                  <TableCell>{row.period}</TableCell>
                  <TableCell>{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </GraphContainer>
    </Container>
  );
};

export default HistoryDataChanged;
