import { getDate, getMonth, getYear } from 'date-fns'

interface MakeFirstLastDateData {
  firstDate: Date
  lastDate: Date
}

interface MakeFirstLastDateDataResponse {
  firstDate: string
  lastDate: string
}

export const makeFirstLastDateData = ({ firstDate, lastDate }: MakeFirstLastDateData): MakeFirstLastDateDataResponse => {
  const firstMonthString = `${getMonth(firstDate) + 1}`
  const firstDateString = `${getDate(firstDate)}`
  const lastMonthString = `${getMonth(lastDate) + 1}`
  const lastDateString = `${getDate(lastDate)}`

  return {
    firstDate: `${getYear(firstDate)}-${firstMonthString.length > 1 ? firstMonthString : '0' + firstMonthString
      }-${firstDateString.length > 1 ? firstDateString : '0' + firstDateString
      }`,
    lastDate: `${getYear(lastDate)}-${lastMonthString.length > 1 ? lastMonthString : '0' + lastMonthString
      }-${lastDateString.length > 1 ? lastDateString : '0' + lastDateString
      }`
  }
}
