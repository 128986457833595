import { FC } from 'react'
import { AtendimentosRealizados, AtendimentosTempoMedio, ChamadosAbertosFechados, MotivosChamados } from './graphs'
import { Container } from './styles'

interface SysAidChartsProp {
  mobile: boolean
}
const SysAidCharts: FC<SysAidChartsProp> = ({ mobile }) => {
  return (
    <Container>
      <AtendimentosRealizados mobile={mobile} />
      <MotivosChamados mobile={mobile} />
      <AtendimentosTempoMedio mobile={mobile} />
      <ChamadosAbertosFechados mobile={mobile} />
    </Container>
  )
}

export default SysAidCharts
