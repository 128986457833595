import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > footer {
    width: 100%;
    text-align: center;
  }
`;

export const GraphTitle = styled.h1`
  color: ${({theme}) => theme.palette.primary.main};
  font-weight: bold;
  text-align: left;
  margin: 0;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphSubtitle = styled.span`
  display: block;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 300px;
  flex: 3;

  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

