import {Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import Select from 'react-select';
import {theme} from 'theme/theme';
import {DateValues} from 'protocols';
import {getMirrorReport, SubmitParams} from 'services/mirrorReport';
import FilterBar from '../FilterBar';
import {SuccessfulCallsPageOne, SuccessfulCallsPageTwo} from './graphs';
import {
  graphListHelper,
  makeOptions,
  orgsOrderDefault,
  percentageDataToOrderBy,
  percentageOrderDefault,
  percentageOrderOptions,
} from './helpers';
import {
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
  OrderSelectContainer,
} from './styles';
import {SuccessfulCallsProps} from './types';
const useStyles = makeStyles(() => ({
  tabsRoot: {
    maxWidth: 400,
    minWidth: 200,
    flex: 1,
  },
  tabLabelRoot: {
    color: theme.palette.secondary.main,
    fontSize: '1em',
    padding: '2rem 1rem',
    textTransform: 'none',
  },
  tabLabelSelected: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
}));

const SuccessfulCalls: FC<SuccessfulCallsProps> = ({orgsData, mobile}) => {
  const classes = useStyles();

  const [submitChartOne, setSubmitChartOne] = useState<
    DateValues | undefined
  >();
  const [submitChartTwo, setSubmitChartTwo] = useState<
    DateValues | undefined
  >();

  const [selectOptions, setSelectOptions] = useState<any[]>([]);
  const [currentKey, setCurrentKey] = useState(0);

  const [totalSumInvocations, setTotalSumInvocations] = useState(0);
  const [pageOneData, setPageOneData] = useState<any>();
  const [pageTwoData, setPageTwoData] = useState<any>();

  const [firstChartOrder, setFirstChartOrder] = useState(
    percentageOrderDefault(),
  );
  const [secondChartOrder, setSecondChartOrder] = useState(orgsOrderDefault());

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, []);

  useEffect(() => {
    setSelectOptions(makeOptions(currentKey));
  }, [currentKey]);

  const handleTabs = (e: ChangeEvent<{}>, newKey: number): void => {
    setCurrentKey(newKey);
  };

  const selectChange = (option: any): void => {
    const orderList: any = {
      0: setFirstChartOrder,
      1: setSecondChartOrder,
    };

    orderList[currentKey](option || percentageOrderOptions[0]);
  };

  const currentSelectedFilter = (): any => {
    return currentKey === 0 ? firstChartOrder : secondChartOrder;
  };

  const makeChartData: {[index: number]: () => void} = {
    0: () => {
      if (submitChartOne) {
        const {firstDate, lastDate, servers} = submitChartOne;

        getChartData(
          {firstDate, lastDate, servers, type: 'successfulCalls'},
          data => {
            setPageOneData(
              percentageDataToOrderBy.map(item => ({
                ...item,
                value: data[item.key],
              })),
            );
            setTotalSumInvocations(data.totalSumInvocations);
          },
        );
      }
    },
    1: () => {
      if (submitChartTwo) {
        const {firstDate, lastDate, servers} = submitChartTwo;

        getChartData(
          {firstDate, lastDate, servers, type: 'successfulCallsByServer'},
          data => setPageTwoData(data),
        );
      }
    },
  };
  const getChartData = useCallback(
    (dateData: SubmitParams, setChart: (data: any) => void): void => {
      setLoading(true);
      getMirrorReport({...dateData}).then(
        (data: any) => {
          setChart(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitChartOne, submitChartTwo],
  );

  useEffect(() => {
    makeChartData[currentKey]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitChartOne, submitChartTwo]);

  const getDataToExportCSV = (): any => {
    if (pageOneData && currentKey === 0) {
      return pageOneData.map(({range, value}: any): any => ({
        intervalo: range,
        valor: value,
      }));
    }
    if (pageTwoData && currentKey === 1) {
      return pageTwoData.map(({serverName, invocations}: any): any => ({
        instituicao: serverName,
        valor: invocations,
      }));
    }
    return '';
  };

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={'chamadas-api-bem-sucedidas.csv'}
        style={{
          display: !mobile ? 'none' : 'block',
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      >
        <h3>
          <FaSave color={theme.palette.secondary.main} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Chamadas de APIs Bem-sucedidas</GraphTitle>
          <GraphSubtitle>
            Demonstração do número de chamadas bem-sucedidas das APIs de
            instituições participantes
          </GraphSubtitle>
        </div>

        {currentKey === 0 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartOne}
          />
        )}
        {currentKey === 1 && (
          <FilterBar
            orgsData={orgsData || []}
            submitValues={setSubmitChartTwo}
          />
        )}

        <CSVLink
          data={getDataToExportCSV()}
          filename={'chamadas-api-bem-sucedidas.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      <OrderSelectContainer>
        <span>Classifique por:</span>
        <Select
          options={selectOptions}
          value={currentSelectedFilter()}
          onChange={selectChange}
        />
      </OrderSelectContainer>

      <GraphContainer>
        <Tabs
          orientation='vertical'
          variant='fullWidth'
          value={currentKey}
          onChange={handleTabs}
          className={classes.tabsRoot}
          TabIndicatorProps={{style: {display: 'none'}}}
        >
          {graphListHelper.map(item => (
            <Tab
              key={`${item.title}-${item.key}`}
              classes={{
                root: classes.tabLabelRoot,
                selected: classes.tabLabelSelected,
              }}
              label={item.title}
              wrapped
              value={item.key}
            />
          ))}
        </Tabs>
        {currentKey === 0 && (
          <SuccessfulCallsPageOne
            data={pageOneData}
            chartOrder={firstChartOrder}
            totalSumInvocations={totalSumInvocations}
            mobile={mobile}
            loading={loading}
          />
        )}
        {currentKey === 1 && (
          <SuccessfulCallsPageTwo
            data={pageTwoData}
            chartOrder={secondChartOrder}
            mobile={mobile}
            loading={loading}
          />
        )}
      </GraphContainer>

      {currentKey === 0 ? (
        <footer>
          <div>Quantidade de Instituições participantes</div>
        </footer>
      ) : (
        <footer>
          <div>
            Apenas instituições que reportaram Chamadas de APIs Bem-sucedidas no
            período selecionado são exibidas.
          </div>
        </footer>
      )}
    </Container>
  );
};

export default SuccessfulCalls;
