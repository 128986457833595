import { SelectDefaultValue } from '../../../protocols'
import { errorsPercentageOrderOptions, errrosOrgsOrderOptions } from './errorsGraphOrder'
import { orgsOrderOptions, percentageOrderOptions } from './graphOrder'

export const makeOptions = (key: number): SelectDefaultValue[] => {
  if (key === 0) {
    return percentageOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }

  if (key === 1) {
    return orgsOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }

  if (key === 2) {
    return errorsPercentageOrderOptions.map(option => ({
      label: option.title,
      value: option.order
    }))
  }

  return errrosOrgsOrderOptions.map(option => ({
    label: option.title,
    value: option.order
  }))
}
