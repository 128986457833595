import {TextField} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {subDays, subMonths} from 'date-fns';
import {useState} from 'react';
import {DateValuesOnlyWithDatePickers} from '../../protocols';
import {Container} from './styles';

interface FilterBarOnlyWithDatePickerProps {
  blockInitialDate?: Date;
  submitValues: (value: DateValuesOnlyWithDatePickers) => void;
}
const FilterBarOnlyWithDatePicker = ({
  blockInitialDate,
  submitValues,
}: FilterBarOnlyWithDatePickerProps): JSX.Element => {
  const [firstDate, setFirstDate] = useState<Date>(subMonths(new Date(), 1));

  const [lastDate, setLastDate] = useState<Date>(subDays(new Date(), 1));

  const [openLastDatePicker, setOpenLastDatePicker] = useState(false);

  const handleChangeFistDate = (date: any): void => {
    setOpenLastDatePicker(true);
    setFirstDate(date);
  };

  const handleChangeLastDate = (date: any): void => {
    setOpenLastDatePicker(false);
    setLastDate(date);
    submitValues({firstDate, lastDate: date});
  };

  return (
    <Container>
      <div>
        <section>
          <span>De:</span>

          <aside>
            <KeyboardDatePicker
              autoOk
              disableFuture
              allowKeyboardControl={false}
              inputVariant='outlined'
              InputAdornmentProps={{position: 'start'}}
              format='dd/MM/yyyy'
              value={firstDate}
              maxDate={new Date()}
              minDate={blockInitialDate}
              size='small'
              TextFieldComponent={props => (
                <TextField {...props} disabled={true} size='small' />
              )}
              onChange={handleChangeFistDate}
            />
          </aside>
        </section>

        <section>
          <span>Até:</span>

          <aside>
            <KeyboardDatePicker
              autoOk
              disableFuture
              open={openLastDatePicker}
              onClick={() => setOpenLastDatePicker(true)}
              onClose={() => setOpenLastDatePicker(false)}
              allowKeyboardControl={false}
              inputVariant='outlined'
              format='dd/MM/yyyy'
              InputAdornmentProps={{position: 'start'}}
              maxDate={subDays(new Date(), 1)}
              value={lastDate}
              TextFieldComponent={props => (
                <TextField {...props} disabled={true} size='small' />
              )}
              onChange={handleChangeLastDate}
            />
          </aside>
        </section>
      </div>
    </Container>
  );
};

export default FilterBarOnlyWithDatePicker;
