import styled from 'styled-components';
import banner from 'assets/banners/banner-big.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

interface LogoContainerProps {
  hasTitle: boolean;
  mobile: boolean;
}
export const LogoContainer = styled.div<LogoContainerProps>`
  box-shadow: 0 0 2px 0 ${({theme}) => theme.palette.secondary.main};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({hasTitle}) => (hasTitle ? 'center' : 'flex-start')};
  position: fixed;
  background: #fff;
  height: fit-content;
  min-height: 50px;
  padding: ${({mobile}) => (mobile ? '0' : '1rem 8rem')};
  gap: ${({mobile}) => (mobile ? '.3rem' : '6rem')};
  z-index: 999999999999999999999;

  & > img {
    flex: 1;
    max-width: fit-content;
  }

  & > h2 {
    text-align: left;
    margin: 0;
    flex: 2;
  }

  @media (max-width: 699px) {
    flex-direction: column;

    & > h2 {
      text-align: center;
      font-size: 15px;
    }
  }
`;

export const Menu = styled.div`
  width: 100%;
  height: 43px;
  background: ${({theme}) => theme.palette.primary.light};

  nav {
    height: 43px;
    display: flex;
    align-items: center;

    ul {
      display: flex;
      list-style-type: none;
      margin-left: 20px;

      li {
        margin: 10px;
        border-left: 1px solid grey;
        padding: 10px;
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          text-decoration: none;
          color: #fff;
        }
      }

      li:last-child {
        border-right: 1px solid grey;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  margin-top: 95px;

  h1 {
    color: ${({theme}) => theme.palette.primary.main};
    font-size: 50px;
    font-weight: bold;
  }

  p {
    color: ${({theme}) => theme.palette.common.black};
    font-size: 20px;
  }

  .info {
    width: 50%;
  }

  .banner {
    width: 391px;
    height: 533px;
    background-size: 100% 100%;
    background-image: url(${banner});
  }

  @media (max-width: 699px) {
    flex-direction: column;

    .banner {
      width: 320px;
      height: 297px;
    }

    .info {
      width: 100%;

      h1 {
        font-size: 32px;
        text-align: center;
      }

      p {
        text-align: center;
        font-size: 18px;
      }
    }
  }
`;
