import { OrderProps, SelectDefaultValue } from '../../../protocols'

export const errorsPercentageDataToOrderBy = [
  { id: 1, value: 0, key: 'lessThanOne', range: '<1' },
  { id: 2, value: 0, key: 'betweenOneAndTen', range: '1 - 10' },
  { id: 3, value: 0, key: 'betweenTenAndHundred', range: '10 - 100' },
  { id: 4, value: 0, key: 'betweenHundredAndThousand', range: '100 - 1.000' },
  { id: 5, value: 0, key: 'biggerThenHundredThousand', range: '>1.000' }
]

interface OrderOptions {
  id: number
  title: string
  order: OrderProps
}
export const errorsPercentageOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior (Range)', order: { by: 'id', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor (Range)', order: { by: 'id', type: 'desc' } },
  { id: 3, title: 'Menor para o Maior (Qtd)', order: { by: 'value', type: 'asc' } },
  { id: 4, title: 'Maior para o Menor (Qtd)', order: { by: 'value', type: 'desc' } }
]

export const errrosOrgsOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior', order: { by: 'errors', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor', order: { by: 'errors', type: 'desc' } },
  { id: 3, title: 'Ordem alfabética', order: { by: 'serverName', type: 'asc' } }
]

export const errorsPercentageOrderDefault = (): SelectDefaultValue => ({
  label: errorsPercentageOrderOptions[0].title,
  value: errorsPercentageOrderOptions[0].order
})

export const errorsOrgsOrderDefault = (): SelectDefaultValue => ({
  label: errrosOrgsOrderOptions[errrosOrgsOrderOptions.length - 1].title,
  value: errrosOrgsOrderOptions[errrosOrgsOrderOptions.length - 1].order
})
