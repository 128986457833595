import {format, subDays} from 'date-fns';
import _ from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {HistoryChildrenParams} from '../../../../protocols';
import {parseNumberToFixedTwo, parseNumberToLocale} from '../../../../utils';
import {monthFormatter} from '../../../../utils/monthFormat';
import {
  ChartContainer,
  Container,
  GraphContainer,
  GraphSubtitle,
  GraphTitle,
  ScrollInfo,
  TooltipContainer,
} from './styles';

interface PercentLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}
const PercentLabel: any = ({x, y, width, height, value}: PercentLabelProps) => (
  <g>
    <text
      x={x + width / 2}
      y={y - 10}
      fill='#000'
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {parseNumberToLocale(parseNumberToFixedTwo(value), 2)}
    </text>
  </g>
);

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Período: {payload[0].payload.month_year}</span>
        <span>
          Tempo de resposta:{' '}
          {parseNumberToLocale(parseNumberToFixedTwo(payload[0].value), 2)}{' '}
          (milissegundos)
        </span>
      </TooltipContainer>
    );
  }
  return null;
};

const CustomYAxisTick = (number: number): string => {
  return parseNumberToLocale(parseNumberToFixedTwo(number));
};
interface HistoryAverageResponseTimesProps {
  data: HistoryChildrenParams[];
  mobile: boolean;
}
const HistoryAverageResponseTimes: FC<HistoryAverageResponseTimesProps> = ({
  data,
  mobile,
}) => {
  const [filteredData, setFilteredData] = useState<HistoryChildrenParams[]>([]);
  const [showScrollInfo, setShowScrollInfo] = useState(true);

  useEffect(() => {
    if (data) {
      const newData = [...data];
      setFilteredData(
        _.orderBy(
          newData.map(item => ({
            ...item,
            month_year: monthFormatter(item.month_year),
            id: item.month_year.replace('-', ''),
          })),
          ['id'],
          ['asc'],
        ),
      );
    }
  }, []);

  const getDataToExportCSV = (): any => {
    return filteredData.map(({month_year: monthYear, value}: any): any => ({
      periodo: monthYear,
      tempo_resposta: value,
    }));
  };

  return (
    <Container>
      <CSVLink
        data={getDataToExportCSV()}
        filename={'historico-tempo-medio-resposta.csv'}
        style={{
          display: !mobile ? 'none' : 'block',
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      >
        <h3>
          <FaSave color={theme.palette.secondary.main} />
        </h3>
      </CSVLink>

      <header>
        <div>
          <GraphTitle>Tempo Médio de Resposta de Chamada das APIs</GraphTitle>
          <GraphSubtitle>
            Demonstração de tempo médio de resposta de chamada das APIs de
            instituições participantes (em milissegundos)
          </GraphSubtitle>
        </div>

        <CSVLink
          data={getDataToExportCSV()}
          filename={'historico-tempo-medio-resposta.csv'}
          style={{display: mobile ? 'none' : 'block'}}
        >
          <h3>
            <FaSave color={theme.palette.secondary.main} />
          </h3>
        </CSVLink>
      </header>

      <GraphContainer>
        <ChartContainer onScroll={() => setShowScrollInfo(false)}>
          <ResponsiveContainer minHeight={300} minWidth={500}>
            <ComposedChart data={filteredData}>
              <XAxis dataKey='month_year' type='category' />
              <YAxis
                type='number'
                tickCount={4}
                padding={{top: 20}}
                tickLine={false}
                tickFormatter={CustomYAxisTick}
              />
              <Bar
                isAnimationActive={false}
                dataKey='value'
                fill={theme.palette.primary.light}
              >
                <LabelList
                  dataKey='value'
                  position='right'
                  content={PercentLabel}
                />
              </Bar>
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>

          <ScrollInfo showScrollInfo={showScrollInfo}>
            <p>
              Pressione e arraste para a esquerda para visualizar o restante do
              gráfico
            </p>
          </ScrollInfo>
        </ChartContainer>
      </GraphContainer>

      <footer>
        <p style={{margin: 0}}>
          Última atualização em {format(subDays(new Date(), 1), 'dd/MM/yyyy')}
        </p>
      </footer>
    </Container>
  );
};

export default HistoryAverageResponseTimes;
