import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & > footer {
    width: 100%;
    text-align: center;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`;

export const GraphTitle = styled.h1`
  color: ${({theme}) => theme.palette.primary.main};
  font-weight: bold;
  text-align: left;
  margin: 0;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphSubtitle = styled.span`
  display: block;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 500px;
  flex: 3;

  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Legend = styled.div`
  align-self: flex-end;
  justify-content: center;
  margin: 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > span:first-child {
    display: flex;
    align-items: center;
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.palette.common.white};
  color: ${({theme}) => theme.palette.common.white};
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

interface ScrollInfoProps {
  showScrollInfo: boolean;
}
export const ScrollInfo = styled.div<ScrollInfoProps>`
  background-color: rgba(${({theme}) => theme.palette.common.black} 0.5);
  color: ${({theme}) => theme.palette.common.white};
  display: none;
  text-align: center;
  font-size: 20px;

  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    display: ${({showScrollInfo}) => (showScrollInfo ? 'flex' : 'none')};
  }
`;
