import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 500px;
  flex: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ChartContainer = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`

interface ScrollInfoProps {
  showScrollInfo: boolean
}
export const ScrollInfo = styled.div<ScrollInfoProps>`
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  display: none;
  text-align: center;
  font-size: 20px;

  align-items: center;
  justify-content: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    display: ${({ showScrollInfo }) => showScrollInfo ? 'flex' : 'none'};
  }
`

export const Legend = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;

  & > span:first-child {
    display: flex;
    align-items: center;
  }
`

export const TooltipContainer = styled.div`
  background-color: #3c3c3c;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #fff;
  padding: 1rem;

  display: flex;
  flex-direction: column;
`
export const NoData = styled.div`
  background-color: #ececec;
  border-radius: 5px;
  width: 80%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
`
