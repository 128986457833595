import 'antd/dist/antd.css'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    
    body, input, button {
        font-size: 16px;
        line-height: 1.5;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    .apexcharts-toolbar {
      position: absolute;
      top: -3px !important;
      right: auto;
      left: 40px;
      z-index: 0;
    }

    .apexcharts-menu-icon {
      opacity: .7;
      transform: scale(1);
    }

    .apexcharts-menu-open {
      left: 13px;
      right: auto;
    }
`
