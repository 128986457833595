/* eslint-disable @typescript-eslint/restrict-plus-operands */
import _ from 'lodash'
import { parseNumberToFixedTwo, parseNumberToLocale, transformToMonthName } from '../../../../../utils'

export const makeChartData = (data: any): any => {
  const chartData: any[] = []
  Object.keys(data).forEach((key, index) => {
    chartData[index] = {
      monthCode: key.replace(/(\d+)\/(\d+)/gi, '$1'),
      mes: transformToMonthName(key),
      abertos: 0,
      fechados: 0
    }

    data[key].forEach((item: any) => {
      if (item.statusvaluecaption === 'Abertos') {
        chartData[index].abertos += Number(item.total)
      } else {
        chartData[index].fechados += Number(item.total)
      }
    })
  })

  return _.orderBy(chartData, ['monthCode'])
}

export const makePieData = (data: any): any => {
  const chartDataWithPercentage = [
    { id: 1, name: 'Chamados abertos', value: 0, percentage: '0', fill: '#000' },
    { id: 2, name: 'Chamados fechados', value: 0, percentage: '0', fill: '#000' }
  ]
  Object.keys(data).forEach(key => {
    data[key].forEach((item: any) => {
      Object.keys(item).forEach((keyTwo: string) => {
        if (keyTwo === 'statusvaluecaption') {
          if (item[keyTwo] === 'AGUARDANDO REQUISITANTE' || item[keyTwo] === 'ENCAMINHADO N2 ATENDIMENTO') {
            chartDataWithPercentage[0].value += Number(item.total)
          }

          if (item[keyTwo] === 'ATENDIMENTO ENCERRADO' || item[keyTwo] === 'CANCELADO PELO REQUISITANTE' || item[keyTwo] === 'CANCELADO EMAIL RULES') {
            chartDataWithPercentage[1].value += Number(item.total)
          }
        }
      })
    })
  })

  chartDataWithPercentage[0].percentage = parseNumberToLocale(parseNumberToFixedTwo(
    (chartDataWithPercentage[1].value * 100 / chartDataWithPercentage[0].value) - 100
  ))
  chartDataWithPercentage[1].percentage = parseNumberToLocale(parseNumberToFixedTwo(chartDataWithPercentage[1].value * 100 / chartDataWithPercentage[0].value))

  return chartDataWithPercentage
}
