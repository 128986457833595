import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${({theme}) => theme.palette.primary.main};
  border-radius: 20px;
  margin: auto;
  padding: 1rem;
  width: 90%;

  & > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;

    & > div:first-child {
      flex: 1;
    }

    @media (max-width: 425px) {
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
`;
export const GraphTitle = styled.h1`
  color: ${({theme}) => theme.palette.primary.main};
  margin: 0;
  font-weight: bold;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const GraphSubtitle = styled.span`
  display: block;
  margin: 0 0 1rem 0;
  width: 100%;
  text-align: left;

  @media (max-width: 425px) {
    text-align: center;
    margin: 0;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  flex: 3;

  position: relative;
`;

export const TooltipContainer = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.palette.common.white};
  color: ${({theme}) => theme.palette.common.white};
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    color: red;
  }
`;

export const InfoFooterContainer = styled.div`
  margin-top: 1rem;

  & > ul {
    list-style: none;
    margin: 0;
  }
`;
