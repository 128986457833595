import {createTheme, Theme} from '@material-ui/core/styles';
import BloggerSans from 'assets/fonts/Blogger-Sans.woff';

declare module '@material-ui/styles' {
  interface DefaultTheme extends Theme {
    palette: {
      primary: {
        light: string;
        main: string;
        dark: string;
      };
    };
  }
}

const bloggerSans = {
  fontFamily: 'Blogger Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Blogger Sans'),
    local('Blogger-Sans-Regular'),
    url(${BloggerSans}) format('woff')
  `,
};

const theme = createTheme({
  typography: {
    fontFamily: '-apple-system,BlinkMacSystemFont,"Blogger Sans",Arial,serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [bloggerSans],
      },
    },
  },
  palette: {
    primary: {
      light: '#299F25',
      main: '#0f570d',
      dark: '#285a73',
    },
    secondary: {
      light: '#ececec',
      main: '#3c3c3c',
    },
    warning: {
      main: '#ffe191',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
  },
});
export {theme};
