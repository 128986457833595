import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import {FC, useState} from 'react';
import {
  Bar,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {SelectDefaultValue} from '../../../../protocols';
import {
  checkInstitutionsListGraphsStatus,
  numberDecimalFormat,
} from '../../../../utils';
import {Container, NoData, ScrollInfo, TooltipContainer} from './styles';

interface PercentLabelProps {
  x: number;
  y: number;
  width: number;
  value: number;
}
const PercentLabel: any = ({x, y, width, value}: PercentLabelProps) => (
  <g>
    <text
      x={x + width + 30}
      y={y + 10}
      fill='#000'
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {numberDecimalFormat(value, 0)}
    </text>
  </g>
);

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Instituição: {payload[0].payload.serverName}</span>
        <span>Total chamadas: {numberDecimalFormat(payload[0].value, 1)}</span>
      </TooltipContainer>
    );
  }
  return null;
};

interface ErrorsCallsPageTwoProps {
  data: any;
  chartOrder: SelectDefaultValue;
  mobile: boolean;
  loading: boolean;
}

const ErrorsCallsPageTwo: FC<ErrorsCallsPageTwoProps> = ({
  data,
  chartOrder,
  mobile,
  loading,
}) => {
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const filteredData = data?.filter((item: any) => item.errors > 0);
  const status = checkInstitutionsListGraphsStatus(data, loading, filteredData);

  return (
    <>
      {status === 'LOADING' && (
        <Skeleton variant='rect' width={'100%'} height={500} />
      )}

      {status === 'NODATA' && <NoData>Nenhum dado encontrado</NoData>}

      {status === 'HASDATA' && (
        <Container onScroll={() => setShowScrollInfo(false)} mobile={mobile}>
          <ResponsiveContainer
            minWidth={500}
            minHeight={data.length > 17 ? data.length * 30 : 100}
          >
            <ComposedChart
              layout='vertical'
              data={_.orderBy(
                filteredData,
                [chartOrder.value.by],
                [chartOrder.value.type],
              )}
            >
              <XAxis
                type='number'
                hide={true}
                domain={[0, (dataMax: number) => dataMax * 1.2]}
              />
              <YAxis
                dataKey='serverName'
                type='category'
                width={180}
                tickLine={false}
                interval={0}
              />
              <Bar
                isAnimationActive={false}
                dataKey='errors'
                fill='#3c3c3c'
                barSize={15}
              >
                <LabelList
                  dataKey='errors'
                  position='right'
                  content={PercentLabel}
                />
              </Bar>
              {!mobile && <Tooltip content={<CustomTooltip />} />}
            </ComposedChart>
          </ResponsiveContainer>

          <ScrollInfo showScrollInfo={showScrollInfo}>
            <p>
              Pressione e arraste para a esquerda para visualizar o restante do
              gráfico
            </p>
          </ScrollInfo>
        </Container>
      )}
    </>
  );
};

export default ErrorsCallsPageTwo;
