import { OrderProps, SelectDefaultValue } from '../../../protocols'

export const percentageDataToOrderBy = [
  { id: 1, value: 0, key: 'lessThanHundred', range: '<100' },
  { id: 2, value: 0, key: 'betweenHundredAndThousand', range: '100 - 1.000' },
  { id: 3, value: 0, key: 'betweenThousandAndFiveThousand', range: '1.000 - 5.000' },
  { id: 4, value: 0, key: 'betweenFiveThousandAndTenThousand', range: '5.000 - 10.000' },
  { id: 5, value: 0, key: 'biggerThenTenThousand', range: '>10.000' }
]

interface OrderOptions {
  id: number
  title: string
  order: OrderProps
}
export const percentageOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior (Range)', order: { by: 'id', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor (Range)', order: { by: 'id', type: 'desc' } },
  { id: 3, title: 'Menor para o Maior (Qtd)', order: { by: 'value', type: 'asc' } },
  { id: 4, title: 'Maior para o Menor (Qtd)', order: { by: 'value', type: 'desc' } }
]

export const orgsOrderOptions: OrderOptions[] = [
  { id: 1, title: 'Menor para o Maior', order: { by: 'rejections', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor', order: { by: 'rejections', type: 'desc' } },
  { id: 3, title: 'Ordem alfabética', order: { by: 'serverName', type: 'asc' } }
]

export const percentageOrderDefault = (): SelectDefaultValue => ({
  label: percentageOrderOptions[0].title,
  value: percentageOrderOptions[0].order
})

export const orgsOrderDefault = (): SelectDefaultValue => ({
  label: orgsOrderOptions[orgsOrderOptions.length - 1].title,
  value: orgsOrderOptions[orgsOrderOptions.length - 1].order
})
