import {TextField} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {endOfMonth, startOfMonth, subDays, subMonths} from 'date-fns';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import {DateValues} from '../../protocols';
import {makeFirstLastDateData} from '../../utils/makeFirstLastDateData';
import {Control, styles} from './filter-custom-components';
import {Container} from './styles';
import {OrgsData} from './types';

const getFirstLetter = (word: string): string => {
  return word.replace(/(\w{0,1}).+/gi, '$1').toUpperCase();
};

interface SelectData {
  label: string;
  value: string;
}

interface GroupListData {
  label: string;
  options: SelectData[];
}

interface FilterBarProps {
  orgsData: OrgsData[] | [];
  submitValues: (value: DateValues) => void;
}
const FilterBar = ({orgsData, submitValues}: FilterBarProps): JSX.Element => {
  const [firstDate, setFirstDate] = useState(subMonths(new Date(), 1));
  const [lastDate, setLastDate] = useState(subDays(new Date(), 1));
  const [openLastDatePicker, setOpenLastDatePicker] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState<SelectData[]>([]);

  const selectOptions = useMemo((): GroupListData[] => {
    const groupList = _.orderBy(
      _.uniqBy(
        orgsData.map((org: any) => ({
          label: getFirstLetter(org.orgName),
          options: [] as SelectData[],
        })),
        'label',
      ),
      ['label'],
      ['asc'],
    );

    orgsData.forEach(org => {
      const firstLetter = getFirstLetter(org.orgName);

      groupList.forEach(group => {
        if (group.label === firstLetter) {
          group.options.push({
            label: org.orgName,
            value: org.orgId,
          });
        }
      });
    });

    return groupList;
  }, [orgsData]);

  const handleChangeFistDate = (date: any): void => {
    setOpenLastDatePicker(true);
    setFirstDate(date);
  };

  const handleChangeLastDate = (date: any): void => {
    setOpenLastDatePicker(false);
    setLastDate(date);
  };

  useEffect(() => {
    const validSelectedOrgs =
      selectedOrgs.length > 0 ? selectedOrgs.map(org => org.value) : [];
    submitValues({
      ...makeFirstLastDateData({firstDate, lastDate}),
      servers: validSelectedOrgs,
      firstDateOrigin: firstDate,
      lastDateOrigin: lastDate,
    });
  }, [firstDate, lastDate]);

  const onSubmit = (): void => {
    const validSelectedOrgs =
      selectedOrgs.length > 0 ? selectedOrgs.map(org => org.value) : [];
    submitValues({
      ...makeFirstLastDateData({firstDate, lastDate}),
      servers: validSelectedOrgs,
      firstDateOrigin: firstDate,
      lastDateOrigin: lastDate,
    });
  };

  useEffect(() => {
    onSubmit();
  }, [selectedOrgs]);

  return (
    <Container>
      <div>
        <section>
          <span>De:</span>

          <aside>
            <KeyboardDatePicker
              autoOk
              disableFuture
              allowKeyboardControl={false}
              inputVariant='outlined'
              InputAdornmentProps={{position: 'start'}}
              format='dd/MM/yyyy'
              value={firstDate}
              maxDate={new Date()}
              size='small'
              TextFieldComponent={props => (
                <TextField {...props} disabled={true} size='small' />
              )}
              onChange={handleChangeFistDate}
            />
          </aside>
        </section>

        <section>
          <span>Até:</span>

          <aside>
            <KeyboardDatePicker
              autoOk
              disableFuture
              open={openLastDatePicker}
              onClick={() => setOpenLastDatePicker(true)}
              onClose={() => setOpenLastDatePicker(false)}
              allowKeyboardControl={false}
              inputVariant='outlined'
              format='dd/MM/yyyy'
              InputAdornmentProps={{position: 'start'}}
              maxDate={new Date()}
              value={lastDate}
              TextFieldComponent={props => (
                <TextField {...props} disabled={true} size='small' />
              )}
              onChange={handleChangeLastDate}
            />
          </aside>
        </section>
      </div>

      <div>
        <span>Instituições:</span>
        <Select
          isMulti
          closeMenuOnSelect={false}
          onMenuClose={onSubmit}
          hideSelectedOptions={false}
          placeholder='Instituições'
          options={selectOptions}
          components={{Control}}
          styles={styles}
          onChange={(value: any) => setSelectedOrgs(value)}
        />
      </div>
    </Container>
  );
};

export default FilterBar;
