import api from './api'

const handleGetTotalTransmitters = async () => {
  return await api.get(`/participants?count=true`);
};
export const getTotalInstitutions = async (): Promise<any> => {
  return await new Promise(resolve => {
    handleGetTotalTransmitters().then(({ data }) => {
      resolve({
        data: {
          instituicoesCadastradas: data.total,
          instituicoesTransmissoras: data.orgsTransmissoras,
        },
      });
    });
  });
};
