import React, {FC, useEffect, useState} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {getTime, startOfMonth, subMonths} from 'date-fns';
import _ from 'lodash';
import {CSVLink} from 'react-csv';
import {FaSave} from 'react-icons/fa';
import {
  Bar,
  ComposedChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {theme} from 'theme/theme';
import {DateValuesOnlyWithDatePickers} from 'protocols';
import {sysAidGetChamadosAbertosFechados} from 'services/sysAidRequests';
import FilterBar from 'components/FilterBarOnlyWithDatePicker';
import {makeChartData} from './helpers/makeChartData';
import {
  ChartContainer,
  Container,
  Content,
  ErrorContainer,
  InfoFooterContainer,
  LegendContainer,
  LegendItem,
  ScrollInfo,
  TooltipContainer,
} from './styles';

interface CustomLabelProps {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
}
const CustomLabel: any = ({x, y, width, height, value}: CustomLabelProps) => (
  <g>
    <text
      x={x + width / 2}
      y={y - 10}
      fill='#000'
      textAnchor='middle'
      dominantBaseline='middle'
    >
      {value}
    </text>
  </g>
);

const CustomTooltip: FC = ({active, payload}: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <span>Período: {payload[0].payload.mes}</span>
        {payload.map((item: any) => (
          <span key={`${item.name}-${item.value}`}>
            {item.name === 'abertos' ? 'Abertos' : 'Fechados'}: {item.value}
          </span>
        ))}
      </TooltipContainer>
    );
  }
  return null;
};

const renderLegend = (props: any) => {
  const {payload} = props;

  return (
    <LegendContainer>
      {payload.map((entry: any, index: any) => (
        <LegendItem key={`item-${index}`} iconColor={entry.payload.fill}>
          <div></div>
          <p style={{textTransform: 'capitalize'}}>
            {`Chamados ${entry.value}`}
          </p>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};

const CustomXAxisTick = (props: any): any => {
  const {x, y, payload, fill} = props;

  return (
    <g>
      <text x={x} y={y} dy={16} textAnchor='middle' fill={fill}>
        {payload.value}
      </text>
    </g>
  );
};

interface ChamadosAbertosFechadosProps {
  mobile: boolean;
}
const ChamadosAbertosFechados: FC<ChamadosAbertosFechadosProps> = ({
  mobile,
}) => {
  const [data, setData] = useState<any[] | undefined>();
  const [showScrollInfo, setShowScrollInfo] = useState(true);
  const [dateToFilter, setDateToFilter] =
    useState<DateValuesOnlyWithDatePickers>({
      firstDate: startOfMonth(subMonths(new Date(), 1)),
      lastDate: new Date(),
    });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const getData = (): void => {
    if (dateToFilter) {
      setLoading(true);
      sysAidGetChamadosAbertosFechados([
        `${getTime(dateToFilter.firstDate)}`,
        `${getTime(dateToFilter.lastDate)}`,
      ])
        .then(response => {
          if (!response.data) throw Error('Erro na obtenção dos dados');
          if (Object.keys(response.data).length === 0)
            throw Error('Nenhum dado encontrado');

          setData(makeChartData(response.data));
          setLoading(false);
          setError({status: false, message: ''});
        })
        .catch(err => {
          setData(undefined);
          setLoading(false);
          setError({status: true, message: err.message});
        });
    }
  };

  useEffect(() => {
    getData();
  }, [dateToFilter]);

  const getDataToExportCSV = (): any => {
    if (data) {
      return data.map(({abertos, fechados, mes}: any): any => ({
        mes,
        abertos,
        fechados,
      }));
    }
    return '';
  };

  return (
    <>
      {!data && loading ? (
        <Container>
          <header>
            <div>
              <h1>Relação entre Chamados Abertos e Fechados no Service Desk</h1>
              <p>Chamados abertos e fechados no período selecionado</p>
            </div>

            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />
          </header>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </Container>
      ) : (
        <Container>
          <CSVLink
            data={getDataToExportCSV()}
            filename={'chamados-abertos-fechados.csv'}
            style={{
              display: !mobile ? 'none' : 'block',
              width: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <h3>
              <FaSave color={theme.palette.secondary.main} />
            </h3>
          </CSVLink>

          <header>
            <div>
              <h1>Relação entre Chamados Abertos e Fechados no Service Desk</h1>
              <p>Chamados abertos e fechados no período selecionado</p>
            </div>

            <FilterBar
              submitValues={dates =>
                setDateToFilter(prevState => {
                  if (!_.isEqual(dates, prevState)) {
                    prevState = dates;
                  }
                  return prevState;
                })
              }
            />

            <CSVLink
              data={getDataToExportCSV()}
              filename={'chamados-abertos-fechados.csv'}
              style={{display: mobile ? 'none' : 'block'}}
            >
              <h3>
                <FaSave color={theme.palette.secondary.main} />
              </h3>
            </CSVLink>
          </header>

          {error.status && (
            <ErrorContainer>
              {/* <h3>Ops! Algo deu errado.</h3>
              <p>Tente novamento mais tarde.</p> */}
              <p>{error.message}</p>
            </ErrorContainer>
          )}

          {loading && !error.status ? (
            <div style={{width: '100%', margin: 'auto'}}>
              <Skeleton variant='rect' width={'100%'} height={500} />
            </div>
          ) : (
            !error.status && (
              <>
                <Content>
                  <ChartContainer onScroll={() => setShowScrollInfo(false)}>
                    <ResponsiveContainer minHeight={300} minWidth={500}>
                      <ComposedChart data={data}>
                        <XAxis
                          dataKey='mes'
                          type='category'
                          interval={0}
                          tick={<CustomXAxisTick />}
                        />
                        <YAxis
                          type='number'
                          padding={{top: 20}}
                          tickLine={false}
                        />
                        {['abertos', 'fechados'].map(
                          (key: any, index: number) => (
                            <Bar
                              key={`${key}-${index}`}
                              isAnimationActive={false}
                              dataKey={key}
                              fill={
                                key === 'abertos'
                                  ? theme.palette.primary.light
                                  : theme.palette.primary.main
                              }
                            >
                              <LabelList
                                dataKey={key}
                                position='top'
                                content={CustomLabel}
                              />
                            </Bar>
                          ),
                        )}
                        <Legend content={renderLegend} />
                        {!mobile && <Tooltip content={<CustomTooltip />} />}
                      </ComposedChart>
                    </ResponsiveContainer>

                    <ScrollInfo showScrollInfo={showScrollInfo}>
                      <p>
                        Pressione e arraste para a esquerda para visualizar o
                        restante do gráfico
                      </p>
                    </ScrollInfo>
                  </ChartContainer>
                </Content>
                <InfoFooterContainer>
                  <p>Não considera chamados cancelados.</p>
                </InfoFooterContainer>
              </>
            )
          )}
        </Container>
      )}
    </>
  );
};

export default ChamadosAbertosFechados;
