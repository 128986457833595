import styled from 'styled-components';

interface ContainerProps {
  mobile: boolean;
}
export const Container = styled.section<ContainerProps>`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: ${({mobile}) => (mobile ? 'scroll' : 'hidden')};
  flex: 3;

  position: relative;
`;

interface ScrollInfoProps {
  showScrollInfo: boolean;
}
export const ScrollInfo = styled.div<ScrollInfoProps>`
  background-color: rgba(${({theme}) => theme.palette.common.dark}, 0.5);
  color: ${({theme}) => theme.palette.common.white};
  display: none;
  text-align: center;
  font-size: 20px;

  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    display: ${({showScrollInfo}) => (showScrollInfo ? 'flex' : 'none')};
  }
`;

export const TooltipContainer = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.palette.common.white};
  color: ${({theme}) => theme.palette.common.white};
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

export const NoData = styled.div`
  background-color: ${({theme}) => theme.palette.secondary.light};
  border-radius: 5px;
  width: 80%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
