import { OrderProps, SelectDefaultValue } from '../../../protocols'

export const percentageDataToOrderBy = [
  { id: 1, value: 0, key: 'lessThanNinetyFive', range: '<95%' },
  { id: 2, value: 0, key: 'betweenNinetyFiveAndNinetySix', range: '95% - 96%' },
  { id: 3, value: 0, key: 'betweenNinetySixAndNinetySeven', range: '96% - 97%' },
  { id: 4, value: 0, key: 'betweenNinetySevenAndNinetyEight', range: '97% - 98%' },
  { id: 5, value: 0, key: 'betweenNinetyEightAndNinetyNine', range: '98% - 99%' },
  { id: 6, value: 0, key: 'betweenNinetyNineAndNinetyNineDotFive', range: '99% - 99,5%' },
  { id: 7, value: 0, key: 'betweenNinetyNineDotFiveAndHundred', range: '99,5% - 100%' }
]

interface ChartOrderOptions {
  id: number
  title: string
  order: OrderProps
}
export const firstChartOrderOptions: ChartOrderOptions[] = [
  { id: 1, title: 'Menor para o Maior (Range)', order: { by: 'id', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor (Range)', order: { by: 'id', type: 'desc' } },
  { id: 3, title: 'Menor para o Maior (Qtd)', order: { by: 'value', type: 'asc' } },
  { id: 4, title: 'Maior para o Menor (Qtd)', order: { by: 'value', type: 'desc' } }
]

export const secondChartOrderOptions: ChartOrderOptions[] = [
  { id: 1, title: 'Menor para o Maior', order: { by: 'uptime', type: 'asc' } },
  { id: 2, title: 'Maior para o Menor', order: { by: 'uptime', type: 'desc' } },
  { id: 3, title: 'Ordem alfabética', order: { by: 'serverName', type: 'asc' } }
]

export const firstChartOrderDefault = (): SelectDefaultValue => ({
  label: firstChartOrderOptions[0].title,
  value: firstChartOrderOptions[0].order
})

export const secondChartOrderDefault = (): SelectDefaultValue => ({
  label: secondChartOrderOptions[secondChartOrderOptions.length - 1].title,
  value: secondChartOrderOptions[secondChartOrderOptions.length - 1].order
})
